.search-panel {
    @extend %container-full;
    width: 100%;
    max-width: 100%;
    background: $color-bg-1;
    @extend %section-padding-bottom;
    @extend %section-padding-top;
    @extend %shadow;
    position: fixed;
    z-index: 9;
    left: 0;
    right: 0;
    top: 0;
    transform: translateY(-120%);
    @extend %a-all-3;
}
.search-panel.is-visible {
    transform: translateY(0);
}
.search-panel__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
}
.search-panel__title {
    margin-bottom: 0;
    margin-right: 50px;
}
.search-panel__close {
    cursor: pointer;
}
.search-panel__close-icon {
    @extend %icon;
    fill: $color-black-2;
}
.search-panel__close:hover .search-panel__close-icon {
    fill: $color-accent;
}
.search-panel__form {
    display: flex;
    align-items: flex-end;
}
.search-panel__line {
    position: relative;
    width: 100%;
    margin-right: 32px;
}
.search-panel__error {
    display: none;
}
.search-panel__error.is-error {
    display: block;
    @extend %text-3;
    color: $color-red;
    position: absolute;
    z-index: 1;
    left: 0;
    bottom: -18px;
}
