.a-loop-start:hover .a-loop-right {
    animation-name: animLoopRight;
    animation-duration: .9s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-direction: normal;
    fill: $color-accent;
}

@keyframes animLoopRight {
    0% {
        transform: translateX(0);
    }
    50% {
        transform: translateX(16px);
    }
    100% {
        transform: translateX(0);
    }
}
.js .a-appear-top {
    transform: translateY(40px) skewX(.5deg);
    opacity: 0;
}
.js .is-appeared-top .a-appear-top {
    transition-property: transform, opacity;
    transition-duration: .6s;
    transition-timing-function: cubic-bezier(0.7,0,0.3,1);
    transform: translateY(0) skewX(0);
    opacity: 1;
}
.js .is-appeared-top.a-appear-top {
    transition-property: transform, opacity;
    transition-duration: .6s;
    transition-timing-function: cubic-bezier(0.7,0,0.3,1);
    transform: translateY(0) skewX(0);
    opacity: 1;
}
.js .a-appear-swipe {
    position: absolute;
    z-index: 2;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: inherit;
}
.js .a-appear-swipe--bg-1 {
    background: $color-bg-1;
}
.js .a-appear-swipe--bg-2 {
    background: $color-bg-2;
}
.js .a-appear-swipe--bg-3 {
    background: $color-bg-3;
}
.js .a-appear-swipe--bg-white {
    background: $color-white;
}
.js .is-appeared-swipe .a-appear-swipe {
    transition-property: transform;
    transition-duration: .6s;
    transition-timing-function: cubic-bezier(0.7,0,0.3,1);
    transform: translateX(100%);
}
.js .is-appeared-swipe .a-appear-swipe--to-left {
    transition-property: transform;
    transition-duration: .6s;
    transition-timing-function: cubic-bezier(0.7,0,0.3,1);
    transform: translateX(-100%);
}
.js .a-appear-cover {
    position: absolute;
    z-index: 2;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: $color-black-2;
}
.js .is-appeared-cover .a-appear-cover {
    transition-property: transform;
    transition-duration: .6s;
    transition-timing-function: cubic-bezier(0.7,0,0.3,1);
    transform: translateY(-100%);
}
