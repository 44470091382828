.services {
    @extend %container-full;
    @extend %section-padding-bottom;
}
.services__container {
    @extend %container;
}
.services__picture {
    display: block;
    @extend %text-mb-20;
    overflow: hidden;
    position: relative;
}
.services__picture:hover .services__image-wrap {
    transform: scale(1.1);
    transform-origin: center center;
}

.services__row {
    @extend %row;
    @extend %row-margins;
    margin-bottom: 0;
}
.services__left {
    @extend %col-6;
    @extend %column-pr-32;
    margin-bottom: 0;
}
.services__numbers {
    @extend %text-mb-20;
}
.services__title {
    @extend %h3;
    @extend %text-mb-40;
    color: $color-black-1;
    @extend %a-all-3;
    display: block;
}
.services__title-link:hover .services__title {
    transform: translateX(6px);
    color: $color-accent;
}
.services__description {
    @extend %text-2;
    color: $color-black-2;
    @extend %text-mb-40;
}
.services__image-wrap {
    @extend %image-wrap;
    padding-bottom: 41.860465%;
    @extend %a-all;
}
.services__image {
    @extend %image-wrap-img;
    visibility: hidden;
    opacity: 0;
    @extend %a-all;
}
.services.is-appeared-cover .services__image {
    opacity: 1;
    visibility: visible;
}
.image-muar {
    background: $color-black-1;
    position: absolute;
    z-index: 2;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: .7;
}

.services__right {
    @extend %col-6;
    @extend %column-pl-32;
    margin-bottom: 0;
}
.service-children {
    position: relative;
    margin-top: 50px;
}
.service-children__list {
    position: relative;
    margin-bottom: 0;
}
.service-children__item {
    border-top: 1px solid $color-black-5;
    border-bottom: 1px solid $color-black-5;
}
.service-children__item:not(:first-child) {
    margin-top: -1px;
}
.service-children__link {
    padding-top: 16px;
    padding-bottom: 16px;
    padding-right: 16px;
    @extend %flex-between;
}
.service-children__title {
    @extend %text-2;
    color: $color-black-2;
    @extend %column-pr-32;
    @extend %a-all-3;
}
.service-children__link:hover .service-children__title {
    transform: translateX(6px);
    color: $color-accent;
}
.service-children__button {
    margin-left: auto;
}


.service-panel {
    @extend %container-full;
}
.service-panel__row {
    @extend %row;
    @extend %row-margins;
    align-items: stretch;
    margin-bottom: 0;
}
.service-panel__left {
    @extend %col-6;
    @extend %column-pr-32;
    margin-bottom: 0;
}
.service-panel__picture {
    display: block;
    overflow: hidden;
    position: relative;
}
.service-panel__picture:hover .service-panel__image-wrap {
    transform: scale(1.1);
    transform-origin: center center;
}
.service-panel__right {
    @extend %col-6;
    @extend %section-padding-vertical-middle;
    @extend %column-pl-32;
    margin-bottom: 0;
}
.service-panel__inner {
    max-width: 680px;
}
.service-panel__numbers {
    @extend %text-mb-20;
}
.service-panel__title {
    @extend %h3;
    @extend %text-mb-40;
    color: $color-black-1;
    @extend %a-all-3;
    display: block;
}
.service-panel__title-link:hover .service-panel__title {
    transform: translateX(6px);
    color: $color-accent;
}
.service-panel__description {
    @extend %text-2;
    color: $color-black-2;
    @extend %text-mb-40;
}
.service-panel__image-wrap {
    @extend %image-wrap;
    padding-bottom: 56.25%;
    @extend %a-all;
}
.service-panel__image {
    @extend %image-wrap-img;
    visibility: hidden;
    opacity: 0;
    @extend %a-all;
}
