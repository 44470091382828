.preloader {
    position: fixed;
    top: 0;
    z-index: 100;
    min-height: 480px;
    width: 100%;
    height: 100%;
    visibility: hidden;
}
.js .preloader {
    visibility: visible;
}
.preloader__main {
    background: $color-white;
    position: absolute;
    z-index: 99;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
}
.preloader__logo {
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
}
.js .preloader__logo {
    opacity: 0;
}
.preloader__switcher {
    background: $color-bg-2;
    position: absolute;
    z-index: 98;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
}
.loader {
    position: absolute;
    left: 0;
    bottom: 20%;
    width: 100%;
    cursor: default;
    pointer-events: none;
}
.js .loader {
    opacity: 0;
}
.loader__content {
    display: block;
    margin: 0 auto;
    fill: none;
    stroke-width: 6;
}
.loader svg path.loader__circle-bg {
    stroke: #ddd;
}
.loader svg path.loader__circle {
    stroke: $color-accent;
}
.js .content {
    opacity: 0;
}

.preloader-ajax {
    position: fixed;
    top: 0;
    z-index: 100;
    min-height: 480px;
    width: 100%;
    height: 100%;
    visibility: hidden;
}
.js .preloader-ajax {
    visibility: visible;
}
.preloader-ajax__main {
    background: $color-white;
    position: absolute;
    z-index: 99;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.preloader-ajax__logo {
    margin: auto;
}
.preloader-ajax__loading {
    @extend %text-3;
    color: $color-black-4;
    text-transform: uppercase;
    letter-spacing: .5px;
    text-align: center;
    margin-top: 10px;
}
.js .preloader-ajax__logo {
    opacity: 0;
}
.preloader-ajax__switcher {
    background: $color-bg-2;
    position: absolute;
    z-index: 98;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
}
.slogan {
    @extend %h2;
    font-size: 20px;
    margin-top: 10px;
    color: $color-black-3;
    text-align: center;
}