.geography {
    @extend %container-full;
    @extend %section-padding-bottom;
}
.geography__items {
    display: flex;
    align-items: center;
    @extend %section-title-mb;
}
.geography__item {
    @extend %text-2;
    color: $color-black-3;
    border-bottom: 1px solid transparent;
    white-space: nowrap;
    cursor: pointer;
    @extend %a-all-3;
}
.geography__item:hover {
    color: $color-accent;
}
.geography__item.is-active {
    color: $color-black-1;
    border-color: $color-black-1;
}
.geography__item:not(:last-child) {
    margin-right: 20px;
}
.geography__map {
    @extend %section-title-mb;
    position: relative;
}

.map-info__block {
    opacity: 0;
    visibility: hidden;
    @extend %a-all-3;
    transform: translateY(40px);
    display: none;
}
.map-info__block.is-active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    display: block;
}
.map-info__content:not(:last-child) {
    @extend %section-title-mb;
}
.map-info__description {
    @extend %text-2;
    color: $color-black-2;
    @extend %text-mb-40;
    max-width: 688px;
}
.map-info__options {
    @extend %row;
    @extend %row-margins;
    align-items: stretch;
}
.map-info__item {
    @extend %col-4;
    background: $color-white;
    @extend %padding-modal;
    border-left: 2px solid $color-accent;
}
.map-info__digits {
    @extend %h1;
    @extend %text-mb-20;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}
.map-info__measure {
    @extend %text-2;
    margin-bottom: 4px;
    margin-left: 2px;
}
.map-info__text {
    @extend %text-2;
    color: $color-black-2;
    text-align: center;
}
.map-info__projects {
    position: relative;
}
.map-info__projects-item {
    border-top: 1px solid $color-black-5;
    border-bottom: 1px solid $color-black-5;
}
.map-info__projects-item:not(:first-child) {
    margin-top: -1px;
}
.map-info__projects-link {
    padding-top: 16px;
    padding-bottom: 16px;
    padding-right: 16px;
    @extend %flex-between;
}
.map-info__projects-title {
    @extend %text-2;
    color: $color-black-2;
    @extend %column-pr-32;
    @extend %a-all-3;
    max-width: 1024px;
}
.map-info__projects-link:hover .map-info__projects-title {
    transform: translateX(6px);
    color: $color-accent;
}
