@font-face {
    font-display: swap;
    font-family: 'Inter';
    src: url('../fonts/Inter-Regular.woff2') format('woff2'),
    url('../fonts/Inter-Regular.woff') format('woff'),
    url('../fonts/Inter-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-display: swap;
    font-family: 'Inter';
    src: url('../fonts/Inter-Medium.woff2') format('woff2'),
    url('../fonts/Inter-Medium.woff') format('woff'),
    url('../fonts/Inter-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-display: swap;
    font-family: 'Eurostile';
    src: url('../fonts/Eurostile-Bold.woff2') format('woff2'),
    url('../fonts/Eurostile-Bold.woff') format('woff'),
    url('../fonts/Eurostile-Bold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}
