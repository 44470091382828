.service {
    @extend %container-full;
}
.service__quote {
    @extend %section-padding-top;
    @extend %section-padding-bottom;
    max-width: 1024px;
    @extend %h3;
    color: $color-black-1;
    margin-bottom: 0;
}
.service__row {
    @extend %row;
    @extend %row-margins;
    align-items: stretch;
}
.service__left {
    @extend %col-6;
    position: relative;
    @extend %column-pr-32;
}
.service__right {
    @extend %col-6;
    position: relative;
    @extend %column-pl-32;
}
.service__text-top {
    padding-top: 10%;
    padding-bottom: 40%;
}
.service__text-bottom {
    padding-top: 50%;
    padding-bottom: 20%;
}
.service__text-center {
    padding-top: 30%;
    padding-bottom: 30%;
}
.service__wrap-full {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 32px;
    right: 0;
    bottom: 0;
    overflow: hidden;
}
.service__wrap-full--outer {
    width: 912px;
}
.service__wrap-full--right {
    left: auto;
    right: 32px;
}
.service__image-full {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
}
.service__over {
    position: absolute;
    z-index: 1;
    left: 0;
    bottom: 0;
    top: -20%;
    overflow: hidden;
}
.service__image-over {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
.service-inner {
    @extend %container-full;
    background: $color-bg-2;
    overflow: hidden;
    margin-top: 100px;
    margin-bottom: 100px;
}
.service-inner--msi {
    @extend %section-padding-top;
    @extend %section-padding-bottom;
}
.service-inner__quote {
    max-width: 1024px;
    @extend %h3;
    color: $color-black-1;
    padding-top: 100px;
}
.service-inner__row {
    @extend %row;
    @extend %row-margins;
    align-items: stretch;
}
.service-inner__row + .service-inner__row {
    margin-top: 100px;
}
.service-inner__left {
    @extend %col-6;
    position: relative;
    @extend %column-pr-32;
    @extend %section-padding-top;
    @extend %section-padding-bottom;
}
.service-inner__right {
    @extend %col-6;
    position: relative;
    @extend %column-pl-32;
    @extend %section-padding-top;
    @extend %section-padding-bottom;
}
.service-inner__link-list {
    margin-bottom: 50px;
}
.service-inner__msi-title {
    @extend %h2;
    color: $color-black-1;
}
.service-inner__msi-date {
    @extend %h3;
    color: $color-black-1;
    margin-bottom: 24px;
}
.service-inner .service-inner__row li {
    margin-bottom: 24px;
    list-style: disc;
    margin-left: 24px;
}
.service-inner .service-inner__row li:last-child {
    margin-bottom: 0;
}
.service-inner .service-inner__row ul:last-child {
    margin-bottom: 0;
}
.service .service__row li {
    margin-bottom: 24px;
    list-style: disc;
    margin-left: 24px;
}
.service .service__row li :last-child {
    margin-bottom: 0;
}
.service .service__row ul:last-child {
    margin-bottom: 0;
}

