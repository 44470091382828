.hero {
    @extend %container-full;
    min-height: 680px;
    max-height: 880px;
    position: relative;
}
.hero__container {
    @extend %container;
    padding-bottom: 140px;
    padding-top: 140px;
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
}
.hero__display {
    padding-top: 2%;
    position: relative;
    z-index: 2;
    max-width: 688px;
}
.hero__title {
    @extend %display-title;
    margin-bottom: 0;
}
.hero__subtitle-wrap {
    position: relative;
    display: inline-block;
    overflow: hidden;
    @extend %text-mb-40;
}
.hero__subtitle {
    @extend %display-subtitle;
    letter-spacing: 1px;
}
.hero__subtitle-bg {
    background: $color-accent;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.hero__description {
    @extend %text-2;
    color: $color-bg-3;
    @extend %text-mb-40;
}
.hero__button-wrap {
    @extend %flex;
    justify-content: flex-start;
}
.hero__video-button {
    width: 240px;
}
.hero__video-icon {
    fill: $color-white;
}
.hero__video-button:active .hero__video-icon {
    fill: $color-white;
}
.hero__footer {
    position: absolute;
    z-index: 3;
    left: 32px;
    right: 32px;
    bottom: 0;
    @extend %flex-between;
    height: 140px;
}
.social {
    display: flex;
    align-items: center;
    justify-content: center;
}
.social__link {
    display: flex;
    align-items: center;
    cursor: pointer;
}
.social__link:not(:last-child) {
    margin-right: 16px;
}
.social__icon {
    @extend %icon-middle;
    @extend %icon-mr;
    fill: $color-black-2;
}
.hero__social .social__icon {
    @extend %icon;
    fill: $color-white;
}
.hero__social .social__link:not(:last-child) {
    margin-right: 32px;
}
.social__link:hover .social__icon {
    fill: $color-accent-dark;
}
.social__text {
    @extend %text-2;
    line-height: 20px;
    color: $color-black-2;
    display: block;
    @extend %a-all-3;
}
.social__link:hover .social__text {
    color: $color-accent-dark;
}
.hero__social {
    position: relative;
}
.hero__social .social__text {
    color: $color-white;
}
.hero__social-link {
    opacity: .5;
}
.hero__social-link:hover {
    opacity: 1;
}
.hero-project {
    @extend %flex;
    align-items: stretch;
    position: relative;
    height: 140px;
}
.hero-project__item {
    @extend %flex;
    @extend %link;
    padding: 20px;
    background: $color-bg-4;
}
.hero-project__image {
    display: block;
    @extend %text-mr-20;
}
.hero-project__text {
    @extend %text-2;
    color: $color-black-4;
    width: 200px;
    @extend %a-all-3;
}
.hero-project__item:hover .hero-project__text {
    color: $color-accent;
}
.hero-project__button-wrap {
    position: relative;
    background: $color-white;
    width: 40px;
}
.hero-project__button {
    @extend %button;
    @extend %text-4;
    background: $color-white;
    color: $color-black-2;
    transform: rotate(-90deg);
    position: absolute;
    transform-origin: right top;
    width: 140px;
    right: 40px;
    top: 0;
    max-width: 140px;
    @extend %a-all-3;
}
.hero-project__button:hover {
    color: $color-white;
}
.hero-project__button-icon {
    @extend %icon;
    @extend %icon-ml;
    @extend %a-all-3;
}
.hero-project__button:hover .hero-project__button-icon {
    fill: $color-white;
}
.hero-background {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    overflow: hidden;
}
.hero-background__image {
    position: absolute;
    z-index: 1;
    width: 100%;
    display: block;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.hero-background__video {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    display: block;
}

.home-services {
    @extend %container-full;
    @extend %section-padding-vertical;
}
.home-services__container {
    @extend %container;
}
.home-services__item-numbers {
    @extend %text-mb-20;
}
.services-item {
    @extend %row;
    @extend %row-margins;
    margin-bottom: 0;
}
.services-item.services-item--reverse {
    flex-flow: row-reverse;
}
.services-item:not(:last-child)  {
    @extend %text-mb-40;
}
.services-item__content {
    @extend %col-4;
    margin-bottom: 0;
}
.services-item__numbers {
    @extend %text-mb-20;
}
.services-item__title {
    @extend %h3;
    @extend %text-mb-40;
    color: $color-black-1;
    @extend %a-all-3;
    display: block;
}
.services-item__title-link:hover .services-item__title {
    transform: translateX(6px);
    color: $color-accent;
}
.services-item__description {
    @extend %text-2;
    color: $color-black-2;
    @extend %text-mb-40;
}
.services-item__picture {
    @extend %col-8;
    margin-bottom: 0;
    overflow: hidden;
    position: relative;
}
.services-item__image-wrap {
    @extend %image-wrap;
    padding-bottom: 56.25%;
    @extend %a-all;
}
.services-item__picture:hover .services-item__image-wrap {
    transform: scale(1.1);
    transform-origin: center center;
}
.services-item__image {
    @extend %image-wrap-img;
    @extend %a-all;
}

.main-form {
    @extend %container-full;
    @extend %section-padding-vertical;
    background: $color-bg-2;
}
.main-form--mb {
    margin-bottom: 100px;
}
.main-form--mt {
    margin-top: 100px;
}
.main-form__container {
    @extend %container;
}
.main-form__row {
    width: 1024px;
    margin: 0 auto;
    max-width: 100%;
}
.main-form__inner {
    @extend %row;
    @extend %row-margins;
    margin-bottom: 0;
}
.main-form__left {
    @extend %col-6;
    @extend %column-pr-32;
    margin-bottom: 0;
}
.main-form__right {
    @extend %col-6;
    @extend %column-pl-32;
    margin-bottom: 0;
}
.main-form__form {
    padding: 48px;
    background: $color-white;
}
.main-form__description {
    @extend %text-2;
    color: $color-black-2;
    max-width: 480px;
}
.form {
    @extend %flex-center;
    position: relative;
}
.form__content {
    width: 100%;
}
.form__content.is-hidden {
    opacity: 0;
    visibility: hidden;
}
.form-group {
    position: relative;
    margin-bottom: 40px;
}
.form-field-error {
    @extend %text-3;
    color: red;
    margin-top: 2px;
    display: none;
    text-align: right;
    position: absolute;
    bottom: -16px;
    right: 0;
}
.form-field-error.is-visible {
    display: block;
}
.form-hidden {
    display: none;
}
.form-group.is-error input {
    border-color: red;
}
.form-group.is-error select {
    border-color: red;
}
.form-group.is-error textarea {
    border-color: red;
}
input.is-error {
    border-color: red;
}
select.is-error {
    border-color: red;
}
textarea.is-error textarea {
    border-color: red;
}
.is-fly + input::placeholder {
    opacity: 1;
}
.form-group .info__row {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 520px;
    flex-wrap: wrap;
    text-align: center;
}
.form-group .info__row span {
    @extend %text-2;
    color: $color-black-3;
}
.form-group .info__row span + span {
    padding-left: 8px;
}
.upload-remove-button {
    font-size: 24px;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 8px;
}
.upload-remove-button span {
    padding-left: 4px;
    @extend %text-3;
}
.form__success {
    display: none;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    text-align: center;
}
.form__success.is-visible {
    display: flex;
    align-items: center;
    justify-content: center;
}
.form__success-title {
    @extend %h3;
    color: $color-black-1;
}
.form__success-message {
    @extend %text;
    color: $color-black-2;
}
.form__success-reload {
    @extend %text-2;
    color: $color-black-3;
}
.form__policy {
    @extend %text-2;
    color: $color-black-2;
    max-width: 480px;
    text-align: center;
    margin: 20px auto 0;
}
.responsiv-uploader-fileupload {
    padding: 20px;
    border: 1px solid $color-black-5;
}
.upload-empty-message {
    text-align: center;
    margin-top: 8px;
}
.text-muted {
    @extend %text-3;
    color: $color-black-3;
}

.home-projects {
    @extend %container-full;
    @extend %section-padding-vertical;
}
.home-projects--no-pt {
    padding-top: 0;
}
.home-projects--no-pb {
    padding-bottom: 0;
}
.home-projects--mb {
    margin-top: 100px;
}
.home-projects__continer {
    @extend %container;
}
.home-projects__row {
    width: 1024px;
    margin: 0 auto;
}
.projects-slider {
    position: relative;
}
.projects-slider__slide {
    display: flex;
    align-items: center;
    justify-content: center;
}
.projects-slider__button {
    position: absolute;
    z-index: 3;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    opacity: 0;
    visibility: hidden;
    @extend %a-all-3;
}
.projects-slider__slide:hover .projects-slider__button {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
    visibility: visible;
}
.projects-slider__slide:hover .muar {
    opacity: 1;
    visibility: visible;
}
.projects-slider__picture {
    @extend %image-wrap;
    padding-bottom: 56.25%;
}
.projects-slider__image {
    @extend %image-wrap-img;
}
.projects-slider__info {
    margin-top: 24px;
    @extend %flex-between;
    @extend %text-mb-20;
}
.projects-slider__info.is-hidden {
    display: none;
}
.projects-slider__numbers {

}
.projects-slider__nav {
    display: flex;
    align-items: center;
}
.projects-slider__row {
    @extend %row;
    align-items: flex-start;
}
.projects-slider__left {
    @extend %h3;
    @extend %col-6;
    margin-bottom: 0;
    @extend %column-pr-32;
}
.projects-slider__right {
    @extend %col-6;
    @extend %text-2;
    color: $color-black-2;
    @extend %column-pl-32;
}
.projects-thumbs__row {
    @extend %row;
    @extend %row-margins;
    margin-bottom: 0;
    align-items: flex-start;
}
.projects-thumbs__left {
    @extend %col-6;
    @extend %column-pr-32;
    @extend %h3;
    color: $color-black-1;
    margin-bottom: 0;
}
.projects-thumbs__right {
    @extend %col-6;
    @extend %column-pl-32;
    @extend %text-2;
    color: $color-black-2;
    margin-bottom: 0;
}

.home-news {
    @extend %container-full;
    @extend %section-padding-vertical;
    background: $color-bg-2;
}
.home-news__container {
    @extend %container;
}
.news-list {
    @extend %row;
    @extend %row-margins;
    align-items: flex-start;
}
.news-list__item {
    @extend %col-6;
}
.news-list__item:nth-child(odd) {
    @extend %column-pr-32;
}
.news-list__item:nth-child(even) {
    @extend %column-pl-32;
}
.news-list__picture {
    overflow: hidden;
    @extend %text-mb-20;
}
.news-list__image-wrap {
    @extend %image-wrap;
    padding-bottom: 56.25%;
    @extend %a-all-3;
}
.news-list__item:hover .news-list__image-wrap {
    transform: scale(1.1);
    transform-origin: center center;
}
.news-list__image {
    @extend %image-wrap-img;
}
.news-list__cridentials {
    @extend %text-mb-20;
    @extend %flex;
    transition: all 0.2s 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.news-list__item:hover .news-list__cridentials {
    transform: translateX(6px);
    color: $color-accent;
}
.news-list__category {
    @extend %text-2;
    color: $color-black-2;
    @extend %text-mr-10;
}
.news-list__date {
    @extend %text-2;
    color: $color-black-2;
}
.news-list__title {
    @extend %h3;
    margin-bottom: 0;
    transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.news-list__item:hover .news-list__title {
    transform: translateX(6px);
    color: $color-accent;
}

.main-gallery {
    @extend %container-full;
    @extend %section-padding-bottom;
    @extend %section-padding-top;
}
.main-gallery__container {
    @extend %container;
}
.main-gallery__slide {
    max-width: 1024px;
    opacity: .6;
    position: relative;
    background-size: cover;
    background-position: center;
    overflow: hidden;
    padding-bottom: 576px;
}
.main-gallery__icon {
    position: absolute;
    z-index: 3;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    opacity: 0;
    visibility: hidden;
    @extend %a-all-3;
}
.main-gallery__slide.swiper-slide-active:hover .main-gallery__icon {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
    visibility: visible;
}
.main-gallery__caption {
    @extend %text-2;
    @extend %padding-modal;
    @extend %a-all-3;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 2;
    display: flex;
    align-items: flex-end;
    background: rgba(0,0,0,.65);
    color: $color-white;
    opacity: 0;
    transform: translateY(100%);
}
.main-gallery__slide.swiper-slide-active:hover .main-gallery__caption {
    opacity: 1;
    transform: translateY(0);
}
.main-gallery__slide.swiper-slide-active:hover .muar {
    opacity: 1;
    visibility: visible;
}
.main-gallery__slide.swiper-slide-active {
    opacity: 1;
}
.main-gallery__row {
    width: 1024px;
    margin: 0 auto;
    max-width: 100%;
}
.main-gallery__info {
    margin-top: 24px;
    @extend %flex-between;
    @extend %text-mb-20;
}
.main-gallery__nav {
    display: flex;
    align-items: center;
}
