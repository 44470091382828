.swiper-control__prev {
    margin-right: 16px;
}
.swiper-control__next {
    margin-left: 16px;
}
.swiper-control__fraction-current {
    @extend %text-2;
    color: $color-black-1;
    line-height: 14px;
    margin-bottom: 4px;
    width: 18px;
    padding-right: 2px;
    display: block;
}
.swiper-control__fraction-total {
    @extend %text-3;
    line-height: 20px;
    color: $color-black-4;
    width: 18px;
    padding-left: 2px;
    display: block;
}
.swiper-control__prev.is-disabled .button-control {
    cursor: default;
    border: 0;
}
.swiper-control__next.is-disabled .button-control {
    cursor: default;
    border: 0;
}
.swiper-control__prev.is-disabled .button-control__icon {
    fill: $color-black-4;
}
.swiper-control__next.is-disabled .button-control__icon {
    fill: $color-black-4;
}
.swiper-control__prev.is-disabled .button-control.button-control--left:hover .button-control__icon:first-child {
    transform: none;
}
.swiper-control__prev.is-disabled .button-control.button-control--left:hover .button-control__icon:last-child {
    transform: translateX(300%);
}
.swiper-control__next.is-disabled .button-control:hover .button-control__icon:first-child {
    transform: translateX(-300%);
}
.swiper-control__next.is-disabled .button-control:hover .button-control__icon:last-child {
    transform: none;
}
.swiper-pagination-fraction {
    width: auto;
    bottom: initial;
    left: initial;
    display: flex;
    align-items: flex-end;
}
.swiper-control .is-lock {
    display: none;
}
.swiper-container.is-hidden {
    display: none;
}
