.props {
    @extend %container-full;
    @extend %section-padding-bottom;
}
.props__container {
    @extend %container;
}
.props__row {
    @extend %row;
    @extend %row-margins;
    margin-bottom: 0;
    align-items: flex-start;
}
.props__row:not(:last-child) {
    margin-bottom: 50px;
}
.props__left {
    @extend %col-3;
    margin-bottom: 0;
}
.props__right {
    @extend %col-9;
    margin-bottom: 0;
}
