.links-list {
    @extend %row;
    @extend %row-margins;
}
.links-list__item {
    @extend %col-4;
    text-align: center;
    @extend %padding-modal;
    background: $color-white;
}
.links-list__picture {
    @extend %image-wrap;
    @extend %text-mb-20;
    padding-bottom: 40%;
    background: $color-white;
}
.links-list__image {
    @extend %image-wrap-img;
    max-width: 80%;
    width: auto;
    max-height: 80%;
    height: auto;
}
.links-list__name {
    @extend %h4;
    color: $color-black-2;
    @extend %text-mb-10;
}
.links-list__link {
    @extend %text-2;
    color: $color-accent;
    @extend %link-underline;
    text-align: center;
}
