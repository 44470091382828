.project {
    @extend %container-full;
    @extend %section-padding-bottom;
}
.project__info {
    padding-bottom: 50px;
}
.info-row {
    @extend %flex;
    align-items: flex-start;
}
.info-row--mt {
    margin-top: 20px;
}
.info-row--j-start {
    justify-content: flex-start;
}
.info-row__type {
    display: block;
    flex: 0 0 170px;
}
.info-row__type:not(:last-child) {
    padding-right: 24px;
}
.info-row__subtitle {
    @extend %text-3;
    color: $color-black-1;
    font-weight: 500;
    margin-bottom: 4px;
}
.info-row__title {
    @extend %h4;
    color: $color-black-2;
    @extend %text-mb-10;
    transition: all .2s cubic-bezier(.25,.46,.45,.94);
}
.info-row__text {
    @extend %text-3;
    color: $color-black-2;
}
.project__slider {
    margin-bottom: 50px;
}
.project-thumbs {
    margin-top: 20px;
}
.project-thumbs__thumb {
    width: 100%;
    padding-bottom: 56.25%;
}
.project-thumbs__thumb {
    width: 100%;
    padding-bottom: 56.25%;
    background-position: center;
    background-size: cover;
    border: 2px solid transparent;
    cursor: pointer;
    @extend %a-all-3;
}
.swiper-slide-thumb-active .project-thumbs__thumb {
    border-color: $color-accent;
}
.project-thumbs__thumb:hover {
    border-color: $color-accent;
}
.project__subtitle {
    @extend %h2;
}

.related {
    @extend %container-full;
    @extend %section-padding-vertical;
    border-top: 1px solid $color-black-5;
}
.related__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
}
.related__nav {
    display: flex;
    align-items: center;
}
.related-slider__picture {
    width: 100%;
    margin-bottom: 20px;
    overflow: hidden;
}
.related-slider__image {
    padding-bottom: 56.25%;
    background-size: cover;
    background-position: center;
    @extend %a-all-3;
}
.related-slider__slide:hover .related-slider__image {
    transform: scale(1.1);
}
.related-slider__info {
    @extend %text-2;
    margin-bottom: 5px;
    color: $color-black-3;
}
.related-slider__title {
    @extend %text;
    color: $color-black-1;
    @extend %link-underline;
}
.related-slider__slide:hover .related-slider__title {
    background-position: 0 100%;
    color: $color-black-2;
}
