@media (max-width: 1920px) {
    %container {
        max-width: 1280px;
    }
}
@media (max-width: 1199.98px) {
    %display-title {
        font-size: 41px;
        line-height: 1.2;
    }
    %display-subtitle {
        font-size: 22.5px;
    }
    %h1 {
        font-size: 35.20px;
    }
    %h2 {
        font-size: 25.59px;
    }
    %h3 {
        font-size: 19.84px;
    }
    %h4 {
        font-size: 14.9px;
    }
    %text {
        font-size: 14.9px;
    }
    %text-2 {
        font-size: 13.02px;
    }
    %text-3 {
        font-size: 10.85px;
    }
    %text-4 {
        font-size: 9.04px;
    }
    .header__row {
        height: 60px;
    }
    .logo {
        max-width: 170px;
    }
    .menu-horizontal {
        display: none;
    }
    .header__phone-text {
        display: none;
    }
    .header__right .button-rounded {
        display: none;
    }
    .header__phone-icon {
        margin-right: 0;
    }
    .hamburger {
        display: block;
        cursor: pointer;
        width: 18px;
        height: 14px;
        position: relative;
    }
    .hamburger__line {
        display: inline-block;
        width: 18px;
        height: 2px;
        float: left;
        margin-bottom: 4px;
        padding: 0;
        background-color: $color-black-3;
        position: relative;
        -webkit-transition: all .3s cubic-bezier(.25,.46,.45,.94);
        transition: all .3s cubic-bezier(.25,.46,.45,.94);
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
    }
    .hamburger__line:last-child {
        margin-bottom: 0;
    }
    .hamburger__line:before {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 3;
        background-color: rgba(255,255,255,.4);
        will-change: transform,color;
        -webkit-transform: scale3d(0,1,1);
        transform: scale3d(0,1,1);
        -webkit-transform-origin: 100% 50%;
        transform-origin: 100% 50%;
        -webkit-transition-timing-function: cubic-bezier(.25,.46,.45,.94);
        transition-timing-function: cubic-bezier(.25,.46,.45,.94);
    }
    .hamburger__line:first-child:before {
        transition: transform .35s;
    }
    .hamburger__line:nth-child(2):before {
        transition: transform .35s 1s;
    }
    .hamburger__line:last-child:before {
        transition: transform .35s 2s;
    }

    .hamburger.is-opened .hamburger__line:first-child {
        transform: translateY(6px) rotate(-45deg);
    }
    .hamburger.is-opened .hamburger__line:nth-child(2) {
        transform: scaleX(0);
    }
    .hamburger.is-opened .hamburger__line:last-child {
        transform: translateY(-6px) rotate(45deg);
    }
    .mobile-menu {
        display: none;
        position: fixed;
        top: 60px;
        left: 0;
        width: 100%;
        height: calc(100% - 60px);
        align-items: center;
        z-index: 999;
        transform: translateX(-100%);
        will-change: transform;
        overflow: hidden;
        background: $color-white;
        transition: all .3s cubic-bezier(.25,.46,.45,.94);
    }
    .mobile-menu.is-ready {
        display: flex;
    }
    .mobile-menu.is-opened {
        transform: translateX(0);
    }
    .mobile-menu__left {
        width: 100%;
        height: 100%;
        flex: 1 1 100%;
        padding: 40px;
        display: flex;
        flex-flow: column;
        justify-content: center;
        position: relative;
    }
    .submenu {
        height: 0;
        overflow: hidden;
        visibility: hidden;
        margin: 0;
        transition: all .3s cubic-bezier(.25,.46,.45,.94);
    }
    .submenu__list-item {
        display: block;
    }
    .submenu__list-link {
        font-size: 15px;
        line-height: 1.4;
        display: block;
        color: $color-black-3;
    }
    .menu-vertical__item.is-opened .submenu {
        height: auto;
        overflow: visible;
        visibility: visible;
    }
    .menu-vertical__item.is-opened .menu-vertical__icon {
        transform: rotate(180deg);
    }
    .mobile-menu__nav {
        max-height: 408px;
    }
    .submenu__list-item + .submenu__list-item {
        margin-top: 8px;
    }
    .submenu__list {
        margin: 16px 0 24px 16px;
    }
    .mobile-menu__right {
        width: 46.666666662vh;
        height: 100%;
        flex: 1 1 46.666666662vh;
        background-position: center;
        background-size: cover;
    }
    .menu-vertical {
        width: 100%;
    }
    .menu-vertical__items {
        display: block;
        margin-bottom: 0;
    }
    .menu-vertical__item + .menu-vertical__item {
        margin-top: 20px;
    }
    .menu-vertical__link {
        display: flex;
        align-items: center;
    }
    .menu-vertical__text {
        font-family: $font-title-family;
        font-weight: 400;
        color: $color-black-2;
        font-size: 21.33px;
        line-height: 1.4;
    }
    .menu-vertical__icon {
        width: 12px;
        height: 12px;
        background: transparent;
        fill: $color-black-2;
        flex: 0 0 12px;
        margin-left: 8px;
        transition: all .3s cubic-bezier(.25,.46,.45,.94);
    }
    .mobile-contacts {
        margin-top: 50px;
    }
    .mobile-contacts__title {
        font-family: $font-title-family;
        font-weight: 400;
        color: $color-black-2;
        font-size: 21.33px;
        line-height: 1.4;
        margin-bottom: 16px;
    }
    .mobile-contacts__item {
        display: flex;
        align-items: center;
        font-family: $font-text-family;
        font-weight: 400;
        color: $color-black-2;
        font-size: 16px;
        line-height: 1.5;
    }
    .mobile-contacts__item + .mobile-contacts__item {
        margin-top: 8px;
    }
    .mobile-contacts__item-icon {
        width: 20px;
        height: 20px;
        background: transparent;
        flex: 0 0 20px;
        fill: $color-black-2;
        margin-right: 8px;
    }
    .mobile-social {
        position: absolute;
        bottom: 40px;
        right: 40px;
        transition: all .3s cubic-bezier(.25,.46,.45,.94);
    }
    .mobile-social.is-hidden {
        opacity: 0;
    }
    .home-projects__row {
        max-width: 100%;
        padding-left: 32px;
        padding-right: 32px;
    }
    .main-gallery__slide {
        max-width: 768px;
        padding-bottom: 480px;
    }
    .footer-top__item {
        max-width: 190px;
    }
}
@media (max-width: 1023.98px) {
    %section-padding-vertical {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    %section-padding-top {
        padding-top: 50px;
    }
    %section-padding-bottom {
        padding-bottom: 50px;
    }
    %section-padding-top-middle {
        padding-top: 25px;
    }
    %section-padding-bottom-middle {
        padding-bottom: 25px;
    }
    %section-title-mb {
        margin-bottom: 25px;
    }
    %text-mb-20 {
        margin-bottom: 12px;
    }
    %text-mb-40 {
        margin-bottom: 20px;
    }
    %section-padding-bottom {
        padding-bottom: 30px;
    }
    %container {
        padding-left: 24px;
        padding-right: 24px;
    }
    %display-title {
        font-size: 24px;
        line-height: 1.5;
    }
    %display-subtitle {
        font-size: 16px;
    }
    %h1 {
        font-size: 28.87px;
    }
    %h2 {
        font-size: 20.98px;
    }
    %h3 {
        font-size: 17.84px;
    }
    %h4 {
        font-size: 14.9px;
    }
    %text {
        font-size: 14.9px;
    }
    %text-2 {
        font-size: 13.02px;
    }
    %text-3 {
        font-size: 10.85px;
    }
    %text-4 {
        font-size: 9.04px;
    }

    .header__row {
        height: 48px;
    }
    .logo {
        max-width: 140px;
    }
    .hero__display {
        text-align: center;
    }
    .hero__button-wrap {
        justify-content: center;
    }
    .hero__footer {
        display: none;
    }
    .mobile-menu {
        top: 48px;
        height: calc(100% - 48px);
    }
    .mobile-menu__left {
        padding: 28px;
    }
    .mobile-menu__right {
        display: none;
    }
    .modal__container {
        max-width: 100%;
        min-width: initial;
        padding: 24px;
    }
    .search-panel {
        padding-top: 80px;
        padding-bottom: 50px;
    }
    .search-panel__header {
        margin-bottom: 20px;
    }
    .breadcrumbs {
        padding-bottom: 25px;
    }
    .services-item:not(:last-child) {
        margin-bottom: 50px;
    }
    .services-item.services-item--reverse {
        flex-flow: row;
        flex-wrap: wrap;
    }
    .services-item__content {
        width: calc((100% / 12) * 12 - 32px);
        margin-bottom: 24px;
    }
    .services-item__picture {
        width: calc((100% / 12) * 12 - 32px);
    }
    .main-form__left {
        width: calc((100% / 12) * 12 - 32px);
        margin-bottom: 24px;
    }
    .main-form__right {
        width: calc((100% / 12) * 12 - 32px);
        padding: 0;
    }
    .main-form__form {
        padding: 28px;
    }
    .section-titlewrap {
        flex-wrap: wrap;
        padding-right: 0;
    }
    .section-titlewrap__title {
        width: 100%;
    }
    .section-titlewrap__title + .goto-link {
        margin-top: 8px;
    }
    .projects-thumbs__row {
        flex-wrap: wrap;
    }
    .projects-thumbs__left {
        width: calc((100% / 12) * 12 - 32px);
        padding-right: 0;
        margin-bottom: 12px;
    }
    .projects-thumbs__right {
        width: calc((100% / 12) * 12 - 32px);
        padding-left: 0;
    }
    .news-list {
        flex-wrap: wrap;
    }
    .news-list__item:nth-child(odd) {
        padding-right: 0;
    }
    .news-list__item:nth-child(even) {
        padding-left: 0;
    }
    .home-news .news-list__item:nth-child(2) {
        display: none;
    }
    .news-list__item {
        width: calc((100% / 12) * 12 - 32px);
    }
    .main-gallery__slide {
        max-width: 70%;
        padding-bottom: 200px;
    }

    .footer-top__row {
        flex-wrap: wrap;
        margin-bottom: 0;
    }
    .footer-top__item {
        width: calc(50% - 32px);
        margin-bottom: 16px;
    }
    .footer-top__item-title {
        margin-bottom: 0;
    }
    .footer-top__item:nth-child(5) {
        width: calc(100% - 32px);
        flex: 0 0 calc(100% - 32px);
        max-width: 100%;
        margin-bottom: 0;
    }
    .footer-top__item-link {
        display: none;
    }
    .footer-top__item:nth-child(5) .footer-top__item-title {
        margin-bottom: 12px;
    }
    .footer-top__item:nth-child(5) .footer-top__item-link {
        display: flex;
    }
    .footer-bottom__row {
        flex-wrap: wrap;
        height: auto;
        padding: 16px 0;
    }
    .footer-bottom__left {
        width: calc((100% / 12) * 12 - 32px);
        text-align: center;
        margin-bottom: 8px;
    }
    .footer-bottom__right {
        width: calc((100% / 12) * 12 - 32px);
        text-align: center;
        justify-content: center;
        margin-bottom: 0;
    }
    .services {
        padding-bottom: 50px;
    }
    .services__row {
        flex-wrap: wrap;
        margin-bottom: 0;
    }
    .services__left {
        width: calc((100% / 12) * 12 - 32px);
        margin-bottom: 24px;
        padding-right: 0;
    }
    .services__right {
        width: calc((100% / 12) * 12 - 32px);
        padding-left: 0;
    }
    .service-children {
        margin-top: 0;
    }
    .service-children__link {
        padding-top: 12px;
        padding-bottom: 12px;
    }
    .all-services__item {
        width: calc((100% / 12) * 12 - 32px);
    }
    .all-services__title {
        padding-right: 24px;
    }
    .breadcrumbs__name {
        white-space: nowrap;
    }
    .service__row {
        flex-wrap: wrap;
    }
    .service__left {
        width: calc((100% / 12) * 12 - 32px);
        padding-right: 0;
    }
    .service__right {
        width: calc((100% / 12) * 12 - 32px);
        padding-left: 0;
    }
    .service__text-top {
        padding: 0;
    }
    .service__wrap-full {
        position: relative;
        left: 0;
        margin-bottom: 0;
    }
    .bg-full {
        padding-bottom: 240px;
    }
    .service__text-bottom {
        padding: 0;
    }
    .service__text-center {
        padding: 24px 0 0;
    }
    .service-inner {
        margin-top: 50px;
        margin-bottom: 50px;
    }
    .service-inner__quote {
        padding-top: 50px;
    }
    .service-inner__left {
        width: calc((100% / 12) * 12 - 32px);
        padding: 0;
        margin-bottom: 24px;
    }
    .service-inner__right {
        width: calc((100% / 12) * 12 - 32px);
        padding: 0 0 50px;
    }
    .home-projects--mb {
        margin-top: 50px;
    }
    .home-projects--no-pt {
        padding-top: 0;
    }
    .main-form--mt {
        margin-top: 50px;
    }
    .service__wrap-full--outer {
        width: 100%;
        margin-top: 50px;
    }
    .service .service__row li:last-child {
        margin-bottom: 0;
    }
    .service__over {
        position: relative;
        top: 0;
        margin-top: 25px;
    }
    .home-projects--no-pb {
        padding-bottom: 0;
    }
    .link-list__item {
        width: calc((100% / 12) * 12 - 32px);
    }
    .project-filter__items {
        overflow-x: auto;
    }
    .projects-list__picture {
        width: calc((100% / 12) * 12 - 32px);
        margin-bottom: 25px;
    }
    .projects-list__info {
        width: calc((100% / 12) * 12 - 32px);
        margin-bottom: 0;
    }
    .projects-list__right {
        display: none;
    }
    .info-row {
        flex-wrap: wrap;
        margin-top: 20px;
    }
    .info-row__type {
        flex: 1 1 50%;
        margin-bottom: 16px;
        padding-right: 20px;
    }
    .info-row__type:nth-child(3) {
        margin-bottom: 0;
    }
    .info-row__type:nth-child(4) {
        margin-bottom: 0;
    }
    .related__header {
        flex-wrap: wrap;
    }
    .related__nav {
        display: none;
    }
    .section-header__row {
        flex-wrap: wrap;
    }
    .section-header__left {
        width: calc((100% / 12) * 12 - 32px);
        margin-bottom: 16px;
        padding-right: 0;
    }
    .section-header__right {
        width: calc((100% / 12) * 12 - 32px);
        padding-left: 0;
    }
    .main-subtitle {
        margin-bottom: 25px;
    }
    .pagination-wrap {
        margin-top: 25px;
        padding-top: 25px;
    }
    .main-subtitle--no-mb {
        margin-bottom: 0;
    }
    .inter-list__left {
        width: calc((100% / 12) * 12 - 32px);
    }
    .inter-list__right {
        display: none;
    }
    .links-list {
        margin-bottom: -25px;
    }
    .links-list__item {
        width: calc((100% / 12) * 12 - 32px);
        padding: 28px;
        margin-bottom: 25px;
    }
    .gallery__item {
        width: calc((100% / 12) * 12 - 32px) !important;
    }
    .gallery__inner {
        padding-bottom: 240px;
    }
    .twin-text__row {
        flex-wrap: wrap;
    }
    .twin-text__left {
        width: calc((100% / 12) * 12 - 32px);
        margin-bottom: 25px;
    }
    .twin-text__right {
        width: calc((100% / 12) * 12 - 32px);
    }
    .advantages__row {
        flex-wrap: wrap;
    }
    .advantages__item {
        width: calc((100% / 12) * 6 - 32px);
    }
    .advantages__item:not(:last-child):after {
        display: none;
    }
    .management__row {
        flex-wrap: wrap;
    }
    .management__item {
        width: calc((100% / 12) * 6 - 32px);
    }
    table.table {
        max-width: 100%;
    }
    .table__title {
        padding: 16px 16px 8px 16px;
        display: block;
    }
    .table__value {
        padding: 8px 16px 16px 16px;
        display: block;
    }
    .table tr {
        display: block;
    }
    .table td {
        display: block;
    }
    .contacts__left {
        width: calc((100% / 12) * 12 - 32px);
    }
    .contacts__inner {
        padding: 0;
    }
    .contacts__right {
        width: calc((100% / 12) * 12 - 32px);
    }
    .contacts__form {
        padding: 28px;
    }
    .contacts__map {
        margin-bottom: 50px;
    }
    .branches__right {
        margin-top: 25px;
        width: calc((100% / 12) * 12 - 32px);
    }
    .branches__text {
        flex: 0 0 100%;
        padding-right: 0;
    }
    .geography__items {
        overflow-x: auto;
    }
    .map-info__options {
        flex-wrap: wrap;
        margin-bottom: -25px;
    }
    .map-info__item {
        width: calc((100% / 12) * 12 - 32px);
        margin-bottom: 25px;
    }
    .hero__subtitle {
        letter-spacing: 0;
    }
}