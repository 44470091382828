.contacts {
    @extend %container-full;
    @extend %section-padding-bottom;
}
.contacts__row {
    @extend %row;
    @extend %row-margins;
    align-items: stretch;
    @extend %section-padding-bottom;
}
.contacts__left {
    @extend %col-4;
}
.contacts__inner {
    padding: 48px 0;
}
.contacts-line {
    display: flex;
    align-items: center;
}
.contacts-line + .contacts-line {
    margin-top: 24px;
}
.contacts-line__icon {
    @extend %icon-large;
    fill: $color-black-2;
    margin-right: 20px;
}
.contacts-line__title {
    @extend %text;
    color: $color-black-1;
    @extend %text-mb-10;
}
.contacts-line__value {
    @extend %text;
    color: $color-black-2;
}
.contacts__right {
    @extend %col-8;
}
.contacts__form {
    padding: 48px;
    background: $color-bg-2;
    height: 100%;
}
.contacts__subtitle {
    @extend %h2;
    color: $color-black-1;
}
.contacts__map {
    margin-bottom: 100px;
}
.map {
    display: block;
    height: 576px;
}
.map [class*="ground-pane"] {
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    filter: url(desaturate.svg#greyscale);
    filter: gray;
    -webkit-filter: grayscale(1);
}
.baloon-title {
    @extend %h4;
    color: $color-black-1;
    margin-bottom: 20px;
    font-weight: 500;
}
.baloon-metro {
    display: flex;
    align-items: center;
    @extend %text-2;
    color: $color-black-2;
    margin-bottom: 5px;
}
.baloon-icon {
    display: block;
    margin-right: 8px;
}
.baloon-phone {
    display: flex;
    align-items: center;
    @extend %text-2;
    color: $color-black-2;
    margin-bottom: 5px;
}
.contacts__branches {
    border-top: 1px solid $color-black-5;
}
.branches {
    padding-top: 32px;
    padding-bottom: 32px;
    border-bottom: 1px solid $color-black-5;
}
.branches__title {
    @extend %h2;
    color: $color-black-1;
}
.branches__row {
    @extend %row;
    @extend %row-margins;
    margin-bottom: 0;
}
.branches__row + .branches__row {
    margin-top: 50px;
}
.branches__picture {
    @extend %col;
    margin-bottom: 0;
    width: 320px;
    flex: 0 0 320px;
    height: 180px;
}
.branches__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
.branches__right {
    @extend %col-8;
    margin-bottom: 0;
    display: flex;
    align-items: flex-start;
}
.branches__text {
    flex: 0 0 65%;
    margin-bottom: 0;
    @extend %column-pr-32;
}
.branches__address {
    @extend %text;
    color: $color-black-2;
    @extend %text-mb-20;
}
.branches__metro {
    display: flex;
    align-items: center;
    @extend %text-2;
    color: $color-black-3;
    @extend %text-mb-10;
}
.branches__icon {
    @extend %icon;
    @extend %icon-mr;
}
.branches__email {
    display: flex;
    align-items: center;
    @extend %text-2;
    color: $color-black-3;
}
.branches__phone {
    margin-bottom: 0;
    @extend %column-pl-32;
}
.branches__phone-item + .branches__phone-item {
    margin-top: 10px;
}
.branches__phone-link {
    @extend %text;
    color: $color-black-2;
}
.branches__phone-addition {
    @extend %text-2;
    color: $color-black-3;
    margin-top: 4px;

}
