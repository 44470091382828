.main-image {
    @extend %container-full;
}
.bg {
    @extend %container-full;
    @extend %section-padding-bottom;
}
.bg__image {
    width: 100%;
    padding-bottom: 41.860465%;
    background-size: cover;
    background-position: center;
}
.half-right {
    @extend %container-full;
    @extend %section-padding-bottom;
}
.half-right__row {
    @extend %row;
    @extend %row-margins;
    margin-bottom: 0;
    justify-content: end;
}
.half-right__col {
    @extend %col-6;
    margin-bottom: 0;
}
.twin-text {
    @extend %container-full;
    @extend %section-padding-bottom;
}
.twin-text__title {
    @extend %h2;
    @extend %text-mb-40;
}
.twin-text__row {
    @extend %row;
    align-items: flex-start;
}
.twin-text__left {
    @extend %col-6;
}
.twin-text__text {
    @extend %text;
    color: $color-black-2;
}
.twin-text__right {
    @extend %col-6;
}
.advantages {
    @extend %section-padding-bottom;
}
.advantages__row {
    @extend %row;
    align-items: stretch;
}
.advantages__item {
    @extend %col-3;
    position: relative;
}
.advantages__item:not(:last-child):after {
    content: '';
    position: absolute;
    z-index: 1;
    right: 0;
    top: 0;
    bottom: 0;
    width: 1px;
    background: $color-black-5;
}
.advantages__title {
    @extend %h1;
    @extend %text-mb-20;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}
.advantages__sup {
    @extend %text-2;
    margin-bottom: 4px;
    margin-left: 2px;
}
.advantages__text {
    @extend %text-2;
    color: $color-black-2;
    @extend %column-pl-32;
    @extend %column-pr-32;
    text-align: center;
}
.bg-full {
    @extend %container-full;
    position: relative;
    padding-bottom: 36.458333%;
    background-size: cover;
    background-position: center;
}
.bg-full__button {
    position: absolute;
    z-index: 3;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}


.full-image {
    position: relative;
    @extend %section-padding-bottom;
}
.full-image__wrap {
    @extend %image-wrap;
    padding-bottom: 39.0625%;
}
.full-image__image {
    @extend %image-wrap-img;
    width: 100%;
}
.full-image__button {
    position: absolute;
    z-index: 3;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.all-services {
    @extend %container-full;
}
.all-services--pt {
    @extend %section-padding-top;
}
.all-services--pb {
    @extend %section-padding-bottom;
}
.all-services__row {
    @extend %row;
    @extend %row-margins;
    align-items: flex-start;
}
.all-services__item {
    @extend %col-6;
}
.all-services__picture {
    overflow: hidden;
    @extend %text-mb-20;
}
.all-services__image-wrap {
    @extend %image-wrap;
    padding-bottom: 56.25%;
    @extend %a-all;
}
.all-services__item:hover .all-services__image-wrap {
    transform: scale(1.1);
    transform-origin: center center;
}
.all-services__image {
    @extend %image-wrap-img;
}
.all-services__link {
    @extend %flex-between;
    @extend %column-pr-32;
    align-items: flex-start;
}
.all-services__title {
    @extend %h3;
    color: $color-black-2;
    margin-bottom: 0;
    padding-right: 64px;
    @extend %a-all-3;
}
.all-services__item:hover .all-services__title {
    transform: translateX(6px);
    color: $color-accent;
}
.all-services__icon {
    @extend %icon;
    fill: $color-black-2;
    margin-top: 5px;
}
.licenses {
    @extend %container-full;
    @extend %section-padding-bottom;
}
.licenses__row {
    @extend %row;
    @extend %row-margins;
}
.licenses__item {
    @extend %col-4;
}
.licenses__image-wrap {
    @extend %image-wrap;
    padding-bottom: 137.229771%;
    overflow: hidden;
}
.licenses__image {
    @extend %image-wrap-img;
}
.licenses__button {
    position: absolute;
    z-index: 3;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    opacity: 0;
    visibility: hidden;
    @extend %a-all-3;
}
.licenses__item:hover .licenses__button {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
    visibility: visible;
}
.licenses__item:hover .muar {
    opacity: 1;
    visibility: visible;
}
.licenses__info {
    margin-top: 24px;
    @extend %text-2;
    color: $color-black-3;
}

.table {
    width: 100%;
    margin-bottom: 24px;
    border-top: 1px solid $color-black-5;
}
.table tr:nth-child(even) {
    background: $color-bg-2;
}
.table__title {
    @extend %text;
    color: $color-black-1;
    font-weight: 500;
    padding: 24px;
}
.table__value {
    @extend %text;
    color: $color-black-2;
    padding: 24px;
}
