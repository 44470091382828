$color-black-1: #0d1317;
$color-black-2: #323639;
$color-black-3: #737678;
$color-black-4: #a1a3a5;
$color-black-5: #d6d7d7;

$color-bg-1: #fafafa;
$color-bg-2: #eff0f0;
$color-bg-3: #e2e3e4;
$color-bg-4: #0d1317;

$color-white: #ffffff;

$color-green: #69c930;

$color-red: #c93030;

$color-accent-dark: #cc4101;
$color-accent: #ff5101;
$color-accent-light: #ff7333;

$font-title-family: 'Inter', Arial, -apple-system, sans-serif;
$font-text-family: 'Inter', Arial, -apple-system, sans-serif;
$font-display-title-family: 'Eurostile', Arial, -apple-system, sans-serif;
