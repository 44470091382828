.vacancies {
    @extend %container-full;
    @extend %section-padding-bottom;
}
.vacancy__description {
    @extend %text;
    color: $color-black-2;
    margin-bottom: 40px;
    padding-bottom: 40px;
    border-bottom: 1px solid $color-black-5;
}
.vacancy__text {
    @extend %text;
    color: $color-black-2;
}
.vacancy__text + .vacancy__text {
    margin-top: 40px;
}
.vacancy {
    @extend %container-full;
    @extend %section-padding-bottom;
}
.vacancy__button {
    margin-top: 50px;
}
.vacancy li {
    margin-left: 24px;
    list-style: disc;
}
.vacancy li + li {
    margin-top: 12px;
}