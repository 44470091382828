.header {
    @extend %container-full;
    border-bottom: 1px solid $color-black-5;
    background: $color-bg-1;
    position: sticky;
    left: 0;
    right: 0;
    top: 0;
    z-index: 1000;
}
.header__container {
    @extend %container;
}
.header__row {
    @extend %flex-between;
    height: 70px;
}
.logo {
    display: block;
    max-width: 200px;
}
.logo__img {
    width: 100%;
    height: auto;
}
.menu-horizontal {
    height: 100%;
}
.menu-horizontal__items {
    @extend %flex;
    flex-wrap: nowrap;
    margin-bottom: 0;
    height: 100%;
}
.menu-horizontal__item {
    height: 100%;
    position: relative;
    cursor: pointer;
}
.menu-horizontal__item:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 0;
    bottom: 0;
    background-color: #ff5101;
    transition: height cubic-bezier(0.25, 0.46, 0.45, 0.94) .35s;
}
.menu-horizontal__item:hover:before {
    height: 100%;
}
.menu-horizontal__link {
    @extend %text-2;
    @extend %link;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 16px;
    padding-right: 16px;
    position: relative;
    z-index: 2;
}
.menu-horizontal__text {
    @extend %a-color;
    font-weight: 500;
    color: $color-black-2;
    position: relative;
    z-index: 2;
}
.menu-horizontal__item:hover .menu-horizontal__text {
    color: $color-white;
}
.menu-horizontal__icon {
    @extend %icon-small;
    @extend %icon-ml;
    @extend %a-fill;
    @extend %a-rotate;
    position: relative;
    z-index: 2;
}
.menu-horizontal__item:hover .menu-horizontal__icon {
    fill: $color-white;
    transform: rotate(180deg);
    transform-origin: (50% 50%);
}
.dropdown-open {
    position: relative;
}
.dropdown {
    transition: opacity .3s ease;
    cursor: default;
    z-index: 998;
    text-align: left;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    visibility: hidden;
    height: 0;
    width: 280px;
}
.dropdown__inner {
    @extend %shadow;
    background: $color-white;
    height: 0;
}
.dropdown__content {
    height: 0;
    position: relative;
}
.dropdown-open:hover .dropdown {
    opacity: 1;
    visibility: visible;
    height: auto;
}
.dropdown-open:hover .dropdown__inner {
    height: auto;
}
.dropdown-open:hover .dropdown__content {
    height: auto;
}
.dropdown__list {
    @extend %padding-modal;
    margin: 0;
}
.dropdown__list-item:not(:last-child) {
    margin-bottom: 10px;
}
.dropdown__list-link {
    @extend %link-underline;
    @extend %text-2;
    color: $color-black-2;
}
.header__right {
    @extend %flex-between;
}
.header__search {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    cursor: pointer;
    margin-right: 20px;
}
.header__search-icon {
    @extend %icon;
}
.header__search:hover .header__search-icon {
    fill: $color-accent-dark;
}
.header__phone {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    @extend %text-2;
    @extend %link;
    font-weight: 500;
    color: $color-black-2;
    margin-right: 20px;
}
.header__phone-icon {
    @extend %icon;
    @extend %icon-mr;
    fill: $color-black-2;
}
.hamburger {
    display: none;
}
.mobile-menu {
    display: none;
}
