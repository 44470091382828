.gallery {
    @extend %container-full;
    @extend %section-padding-bottom;
}
.gallery__items {
    @extend %row;
    @extend %row-margins;
    margin-bottom: -32px;
    align-items: flex-start;
}
.gallery__item {
    overflow: hidden;
}
.gallery__item:nth-child(9n + 1) {
    @extend %col-6;
    margin-bottom: 32px;
}
.gallery__item:nth-child(9n + 2) {
    @extend %col-3;
    margin-bottom: 32px;
}
.gallery__item:nth-child(9n + 3) {
    @extend %col-3;
    margin-bottom: 32px;
}
.gallery__item:nth-child(9n + 4) {
    @extend %col-4;
    margin-bottom: 32px;
}
.gallery__item:nth-child(9n + 5) {
    @extend %col-4;
    margin-bottom: 32px;
}
.gallery__item:nth-child(9n + 6) {
    @extend %col-4;
    margin-bottom: 32px;
}
.gallery__item:nth-child(9n + 7) {
    @extend %col-3;
    margin-bottom: 32px;
}
.gallery__item:nth-child(9n + 8) {
    @extend %col-3;
    margin-bottom: 32px;
}
.gallery__item:nth-child(9n + 9) {
    @extend %col-6;
    margin-bottom: 32px;
}
.gallery__inner {
    @extend %image-wrap;
    background-size: cover;
    background-position: center;
    overflow: hidden;
    padding-bottom: 400px;
}

.gallery__caption {
    @extend %text-2;
    @extend %padding-modal;
    @extend %a-all-3;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 2;
    display: flex;
    align-items: flex-end;
    background: rgba(0,0,0,.65);
    color: $color-white;
    opacity: 0;
    transform: translateY(100%);
}
.gallery__item:hover .gallery__caption {
    opacity: 1;
    transform: translateY(0);
}
.gallery__icon {
    position: absolute;
    z-index: 3;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    opacity: 0;
    visibility: hidden;
    @extend %a-all-3;
}
.gallery__item:hover .gallery__icon {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
    visibility: visible;
}
.gallery__item:hover .muar {
    opacity: 1;
    visibility: visible;
}
