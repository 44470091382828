/* Typography */
%display-title {
    font-family: $font-display-title-family;
    font-weight: 600;
    color: $color-white;
    font-size: 48px;
    line-height: 1.2;
}
%display-subtitle {
    font-family: $font-title-family;
    font-weight: 600;
    color: $color-black-5;
    font-size: 24.19px;
    line-height: 1.5;
}
%h1 {
    font-family: $font-title-family;
    font-weight: 400;
    color: $color-black-1;
    font-size: 37.90px;
    line-height: 1.2;
    margin-bottom: 24px;
}
%h2 {
    font-family: $font-title-family;
    font-weight: 400;
    color: $color-black-1;
    font-size: 28.43px;
    line-height: 1.25;
    margin-bottom: 24px;
}
%h3 {
    font-family: $font-title-family;
    font-weight: 400;
    color: $color-black-1;
    font-size: 21.33px;
    line-height: 1.4;
    margin-bottom: 24px;
}
%h4 {
    font-family: $font-title-family;
    font-weight: 400;
    color: $color-black-1;
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 24px;
}
%text {
    font-family: $font-text-family;
    font-weight: 400;
    color: $color-black-2;
    font-size: 16px;
    line-height: 1.5;
}
%text-2 {
    font-family: $font-text-family;
    font-weight: 400;
    color: $color-black-2;
    font-size: 14px;
    line-height: 1.5;
}
%text-3 {
    font-family: $font-text-family;
    font-weight: 400;
    color: $color-black-2;
    font-size: 11.67px;
    line-height: 14px;
}
%text-4 {
    font-family: $font-text-family;
    font-weight: 400;
    color: $color-black-2;
    font-size: 9.72px;
    line-height: 11px;
}

/* Links */
%link {
    color: $color-accent;
    text-decoration: none;
    cursor: pointer;
    @extend %a-color;
}
%link:hover {
    color: $color-accent-dark;
}
%link-underline {
    cursor: pointer;
    display: inline;
    text-decoration: none;
    color: $color-black-2;
    background-repeat: no-repeat;
    background-image: linear-gradient(to right, rgb(13, 19, 23) 50%, rgba(13, 19, 23, 0.2) 50%);
    background-size: 200% 1px;
    background-position: 100% 100%;
    transition: background-position .3s cubic-bezier(.25,.46,.45,.94), color .3s cubic-bezier(.25,.46,.45,.94);
}
%link-underline:hover {
    background-position: 0 100%;
    color: $color-black-2;
}

/* Spacers */
%section-padding-vertical {
    padding-top: 100px;
    padding-bottom: 100px;
}
%section-padding-vertical-middle {
    padding-top: 50px;
    padding-bottom: 50px;
}
%section-padding-top {
    padding-top: 100px;
}
%section-padding-bottom {
    padding-bottom: 100px;
}
%section-padding-top-middle {
    padding-top: 50px;
}
%section-padding-bottom-middle {
    padding-bottom: 50px;
}
%section-pb-50 {
    padding-bottom: 50px;
}
%section-title-mb {
    margin-bottom: 50px;
}
%main-title-mb {
    margin-bottom: 50px;
}
%main-subtitle-mb {
    margin-bottom: 40px;
}
%main-subtitle-mb-24 {
    margin-bottom: 24px;
}
%section-mb-50 {
    margin-bottom: 50px;
}
%text-mb-40 {
    margin-bottom: 40px;
}
%text-mr-32 {
    margin-right: 32px;
}
%text-mr-10 {
    margin-right: 10px;
}
%text-mr-20 {
    margin-right: 20px;
}
%text-mb-10 {
    margin-bottom: 10px;
}
%text-mb-24 {
    margin-bottom: 24px;
}
%text-mb-20 {
    margin-bottom: 20px;
}
%text-mt-20 {
    margin-top: 20px;
}
%column-pr-32 {
    padding-right: 32px;
}
%column-pl-32 {
    padding-left: 32px;
}
%padding-modal {
    padding: 32px;
}

/* Grid */
%container-full {
    width: 100%;
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
}
%container {
    width: 100%;
    max-width: 1440px;
    padding-left: 32px;
    padding-right: 32px;
    margin-left: auto;
    margin-right: auto;
}
%row-margins {
    margin-left: -16px;
    margin-right: -16px;
}
%col-ml {
    margin-left: 16px;
}
%col-mr {
    margin-right: 16px;
}
%row {
    display: flex;
    align-items: center;
    @extend %row-margins;
    flex-wrap: wrap;
    margin-bottom: -50px;
}
%col {
    width: auto;
    flex: 0 1 auto;
    @extend %col-ml;
    @extend %col-mr;
    margin-bottom: 50px;
}
%col-1 {
    width: calc((100% / 12) * 1 - 32px);
    flex: 0 0 auto;
    @extend %col-ml;
    @extend %col-mr;
    margin-bottom: 50px;
}
%col-2 {
    width: calc((100% / 12) * 2 - 32px);
    flex: 0 0 auto;
    @extend %col-ml;
    @extend %col-mr;
    margin-bottom: 50px;
}
%col-3 {
    width: calc((100% / 12) * 3 - 32px);
    flex: 0 0 auto;
    @extend %col-ml;
    @extend %col-mr;
    margin-bottom: 50px;
}
%col-4 {
    width: calc((100% / 12) * 4 - 32px);
    flex: 0 0 auto;
    @extend %col-ml;
    @extend %col-mr;
    margin-bottom: 50px;
}
%col-5 {
    width: calc((100% / 12) * 5 - 32px);
    flex: 0 0 auto;
    @extend %col-ml;
    @extend %col-mr;
    margin-bottom: 50px;
}
%col-6 {
    width: calc((100% / 12) * 6 - 32px);
    flex: 0 0 auto;
    @extend %col-ml;
    @extend %col-mr;
    margin-bottom: 50px;
}
%col-7 {
    width: calc((100% / 12) * 7 - 32px);
    flex: 0 0 auto;
    @extend %col-ml;
    @extend %col-mr;
    margin-bottom: 50px;
}
%col-8 {
    width: calc((100% / 12) * 8 - 32px);
    flex: 0 0 auto;
    @extend %col-ml;
    @extend %col-mr;
    margin-bottom: 50px;
}
%col-9 {
    width: calc((100% / 12) * 9 - 32px);
    flex: 0 0 auto;
    @extend %col-ml;
    @extend %col-mr;
    margin-bottom: 50px;
}
%col-10 {
    width: calc((100% / 12) * 10 - 32px);
    flex: 0 0 auto;
    @extend %col-ml;
    @extend %col-mr;
    margin-bottom: 50px;
}
%col-11 {
    width: calc((100% / 12) * 11 - 32px);
    flex: 0 0 auto;
    @extend %col-ml;
    @extend %col-mr;
    margin-bottom: 50px;
}
%col-12 {
    width: calc((100% / 12) * 12 - 32px);
    flex: 0 0 auto;
    @extend %col-ml;
    @extend %col-mr;
    margin-bottom: 50px;
}
%flex {
    display: flex;
    align-items: center;
}
%flex-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
%flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Forms */
%input {
    border: 0;
    border-bottom: 1px solid $color-black-3;
    padding: 4px 0;
    @extend %text;
    height: 24px;
    width: auto;
    max-width: 100%;
    background: transparent;
    color: $color-black-1;
    display: block;
    outline: none;
    -webkit-appearance: none;
    border-radius: 0;
    @extend %a-all-3;
}
%input::placeholder {
    @extend %a-all-3;
    @extend %text-2;
    color: $color-black-4;
    opacity: 0;
}
%input:focus::placeholder {
    opacity: 1;
}
%input:read-only {
    opacity: .5;
    border-bottom: 1px solid $color-black-3;
}
%input:read-only:focus {
    opacity: .5;
    border-bottom: 1px solid $color-black-3;
}
%select {
    border: 0;
    border-bottom: 1px solid $color-black-3;
    padding: 0;
    @extend %text;
    height: 24px;
    width: auto;
    max-width: 100%;
    background: transparent;
    color: $color-black-1;
    display: block;
    outline: none;
    border-radius: 0;
    user-select: none;
    @extend %a-all-3;
}
%select:hover {
    border-color: $color-black-2;
    border-width: 1px;
}
%select:focus {
    border-color: $color-black-2;
    border-width: 2px;
}
%textarea {
    border: 0;
    border-bottom: 1px solid $color-black-3;
    padding: 0;
    @extend %text;
    width: auto;
    max-width: 100%;
    background: transparent;
    color: $color-black-1;
    outline: none;
    resize: none;
    height: 26px;
    -webkit-appearance: none;
    border-radius: 0;
    @extend %a-all-3;
}
%textarea::placeholder {
    @extend %a-all-3;
    @extend %text-2;
    color: $color-black-5;
    opacity: 0;
}
%textarea:focus::placeholder {
    opacity: 1;
}
%textarea:hover {
    border-color: $color-black-3;
    border-width: 1px;
}
%textarea:focus {
    border-color: $color-black-2;
    border-width: 2px;
}
%button {
    padding: 0 16px;
    @extend %text-3;
    letter-spacing: .5px;
    font-weight: 500;
    height: 40px;
    width: auto;
    max-width: 100%;
    text-transform: uppercase;
    background: $color-accent;
    color: $color-white;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0;
    cursor: pointer;
    white-space: nowrap;
    user-select: none;
    -webkit-appearance: none;
    @extend %a-all-3;
}
%button:hover {
    background: $color-accent-dark;
    color: $color-white;
}
%button:active {
    background: $color-accent-dark;
    color: $color-white;
}
%button-rounded {
    width: 40px;
    height: 40px;
    flex: 0 0 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: $color-accent;
    border: 0;
    user-select: none;
    outline: none;
    position: relative;
    z-index: 2;
    cursor: pointer;
}
%button-rounded:after {
    content: '';
    position: absolute;
    @extend %a-all-3;
    z-index: 0;
    width: 40px;
    height: 40px;
    border: 0;
    border-radius: 50%;
    background: $color-accent;
    opacity: .5;
}
%button-rounded:hover:after {
    transform: scale(1.33);
}
%button-rounded:hover:before {
    content: '';
    position: absolute;
    z-index: 1;
    width: 40px;
    height: 40px;
    border: 0;
    border-radius: 50%;
    background: $color-accent;
}
%button-rounded:hover {
    background: $color-accent-dark;
}
%button:active {
    background: $color-accent-dark;
}

/* Image */
%image-wrap {
    display: block;
    background: $color-white;
    position: relative;
    width: 100%;
    padding-bottom: 100%;
    overflow: hidden;
}
%image-wrap-img {
    position: absolute;
    z-index: 2;
    max-width: 100%;
    max-height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}
%image-cover {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

/* Icons */
%icon-small {
    width: 12px;
    height: 12px;
    background: transparent;
    fill: $color-black-2;
    flex: 0 0 12px;
}
%icon {
    width: 20px;
    height: 20px;
    background: transparent;
    flex: 0 0 20px;
    fill: $color-black-2;
}
%icon:hover {
    @extend %a-fill;
}
%icon-mr {
    margin-right: 8px;
}
%icon-ml {
    margin-left: 8px;
}
%icon-middle {
    width: 24px;
    height: 24px;
    background: transparent;
    fill: $color-black-2;
    flex: 0 0 24px;
}
%icon-large {
    width: 32px;
    height: 32px;
    background: transparent;
    fill: $color-black-2;
    flex: 0 0 32px;
}

/* Animations */
%a-color {
    transition: color ease .35s;
}
%a-fill {
    transition: fill ease .35s;
}
%a-rotate {
    transition: transform ease .35s;
}
%a-all {
    transition: all .6s cubic-bezier(.25,.46,.45,.94);
}
%a-transform {
    transition: transform .6s cubic-bezier(.25,.46,.45,.94);
}
%a-all-3 {
    transition: all .3s cubic-bezier(.25,.46,.45,.94);
}
%a-all-12 {
    transition: all .6s cubic-bezier(.25,.46,.45,.94);
}

/* Shadows */
%shadow {
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.1), 15px 15px 15px rgba(0, 0, 0, 0.1);
}
%icon-shadow {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.15);
}
