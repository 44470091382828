.management {
    @extend %container-full;
    @extend %section-padding-bottom;
}
.management__container {
    @extend %container;
}
.management-slider {
    @extend %row;
    @extend %row-margins;
}
.management__row {
    @extend %row;
    @extend %row-margins;
    align-items: flex-start;
}
.management__row--reverse {
    justify-content: center;
    flex-flow: wrap-reverse;
    align-items: flex-end;
}
.management-slider.swiper-container {
    margin: 0;
    display: block;
}
.management__item {
    @extend %col-4;
}
.management__item.swiper-slide {
    margin: 0;
    width: auto;
}
.management__picture {
    position: relative;
    overflow: hidden;
    @extend %text-mb-24;
}
.management__image-wrap {
    @extend %image-wrap;
    padding-bottom: 137.229771%;
}
.management__image {
    @extend %image-wrap-img;
}
.management__progress {
    @extend %text-2;
    @extend %padding-modal;
    @extend %a-all-3;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 2;
    display: flex;
    align-items: flex-end;
    background: rgba(0,0,0,.65);
    color: $color-white;
    opacity: 0;
    transform: translateY(100%);
}
.management__item:hover .management__progress {
    opacity: 1;
    transform: translateY(0);
}
.management__name {
    @extend %h3;
    color: $color-black-2;
    margin-bottom: 10px;
}
.management__position {
    @extend %text-2;
    color: $color-black-3;
}
.management__footer {
    @extend %row;
    @extend %row-margins;
    margin-top: 24px;
    justify-content: end;
    margin-bottom: 0;
}
.management__nav {
    @extend %col-4;
    margin-bottom: 0;
    @extend %flex-between;
}
