//.header__right {
//    position: relative;
//}
.footer--is-footer-panel {
    margin-bottom: 64px;
}
.bottom-panel {
    @extend %container-full;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
    height: 64px;
    background: $color-bg-1;
    box-shadow: 0px -4px 6px 0px rgba(34, 60, 80, 0.1);
}
.bottom-panel__inner {
    @extend %container;
    display: flex;
    align-content: center;
    justify-content: center;
    height: 100%;
}
.bottom-panel__item {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 16px;
}
.bottom-panel__link {
    @extend %text-2;
    display: flex;
    align-items: center;
    align-self: center;
    position: relative;
}
.bottom-panel__icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    flex: 0 0 20px;
}
.login-form {
    padding: 48px;
    background: #fff;
}
.login-form__links {
    margin-top: 24px;
}
.login-form__link {
    @extend %text-2;
    display: block;
    text-align: center;
    color: $color-black-2;
    margin-top: 4px;
}
a.login-form__link {
    color: $color-accent;
}
video {
    background: #000;
}
.create-order__item-title {
    @extend %h3;
}
.create-order__item-title a {
    color: $color-black-1;
}
.create-order__item-title a:hover {
    color: $color-accent;
}
.order-list__info {
    display: flex;
    align-items: center;
}
.order-list__info-item {
    display: flex;
    align-items: center;
}
.order-list__info-item + .order-list__info-item {
    margin-left: 24px;
}
.order-list__item {
    padding-bottom: 32px;
    border-bottom: 1px solid;
}
.order-list__items {
    margin-bottom: 0;
    margin-top: 24px;
}
.order-list__title {
    margin-bottom: 16px;
}
.order-list__item + .order-list__item {
    margin-top: 32px;
}
.order-list__items-li + .order-list__items-li {
    margin-top: 8px;
}
.order-list__info-status {
    @extend %text-2;
    color: #fff;
    display: block;
    padding: 4px 6px;
    margin-left: 6px;
}
.is-color-yellow {
    background-color: #dcb208;
}
.is-color-orange {
    background-color: $color-accent;
}
.is-color-blue {
    background-color: #2980b9;
}
.is-color-green {
    background-color: $color-green;
}
.is-color-red {
    background-color: $color-red;
}
.is-red {
    color: $color-red;
}
.create-order__blockquote {
    margin-bottom: 24px;
    margin-left: 8px;
}
.projects-list__checkbox {
    @extend %col-1;
    flex: 0 0 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
}
.checkbox {
    width: 20px;
    height: 20px;
    border: 1px solid $color-black-5;
}
.checkout-panel {
    padding: 48px;
    background: #fff;
}
.checkout-panel + .checkout-panel {
    margin-top: 48px;
}
.checkout-panel__total {
    @extend %h3;
    margin-top: 24px;
    text-align: right;
    margin-bottom: 0;
}
.cart-count {
    @extend %text-3;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: $color-accent;
    color: #fff;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 4px;
}
.cart-positions {
    margin-bottom: 48px;
}
.checkout-panel__data {
    padding-top: 24px;
}
.notification-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
}
.notification {
    padding: 48px;
    background-color: #fff;
}
.notification__message {
    @extend %text;
    display: none;
}
.notification__message.is-visible {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.notification__icon {
    @extend %icon;
    margin-right: 8px;
}
.notification--success svg {
    fill: $color-green;
}
.notification--success span {
    color: $color-green;
}
.notification--error svg {
    fill: $color-red;
}
.notification--error span {
    color: $color-red;
}
.order-list__items-link {
    color: $color-black-2;
}
.oferta-div {
    margin-bottom: 24px;
}

