.footer {
    margin-top: auto;
    @extend %container-full;
}
.footer-top {
    @extend %section-padding-vertical-middle;
    background: $color-bg-3;
}
.footer-top__row {
    @extend %row;
    @extend %row-margins;
    @extend %flex-between;
    align-items: flex-start;
    margin-bottom: 0;
}
.footer-top__item {
    @extend %col;
    margin-bottom: 0;
    max-width: 220px;
}
.footer-top__item-title {
    @extend %text-2;
    @extend %text-mb-20;
    @extend %link;
    font-weight: 500;
    display: block;
    color: $color-black-1;
}
.footer-top__item-icon {
    @extend %icon;
    @extend %icon-mr;
    fill: $color-black-2;
    margin-top: 1px;
}
.footer-top__item-link {
    @extend %text-2;
    @extend %link;
    display: flex;
    align-items: flex-start;
    color: $color-black-2;
}
.footer-top__item-link--not-link {
    cursor: default;
}
.footer-top__item-link--not-link:hover {
    color: $color-black-2;
}
.footer-top__item-link:not(:last-child) {
    @extend %text-mb-10;
}
.footer-bottom {
    background: $color-black-1;
}
.footer-bottom__row {
    @extend %row;
    @extend %row-margins;
    @extend %flex-between;
    margin-bottom: 0;
    height: 60px;
}
.footer-bottom__left {
    @extend %col-6;
    @extend %text-2;
    color: $color-black-4;
    margin-bottom: 0;
}
.footer-bottom__right {
    display: flex;
    justify-content: flex-end;
    @extend %col-6;
    margin-bottom: 0;
}
.footer-bottom__link {
    @extend %link;
    @extend %text-2;
    color: $color-black-4;
}
.footer-bottom__link:not(:last-child) {
    @extend %text-mr-32;
}
.footer-top__item-link + .footer-top__item-title {
    margin-top: 24px;
}
.footer-social {
    cursor: default;
    color: $color-black-1;
}
.footer-social:hover {
    color: $color-black-1;
}
.footer-social + .social {
    justify-content: space-between;
}
.footer-social + .social .social__icon {
    fill: $color-black-2;
    @extend %icon-middle;
}
.footer-social + .social .social__icon:hover {
    fill: $color-accent-dark;
}
.footer-social + .social .social__link:not(:last-child) {
    margin-right: 0;
}
