.post {
    @extend %container-full;
    @extend %section-padding-bottom;
}

.post__summary {
    @extend %text-2;
    color: $color-black-2;
    @extend %text-mb-20;
    padding-bottom: 20px;
    border-bottom: 1px solid $color-black-5;
}
.credentials {
    display: flex;
    align-items: center;
    @extend %text-mb-20;
}
.credentials__item {
    display: flex;
    align-items: center;
    @extend %text-2;
    color: $color-black-3;
}
.credentials__item + .credentials__item {
    margin-left: 32px;
}
.credentials__icon {
    @extend %icon;
    @extend %icon-mr;
    color: $color-black-3;
}
.post__picture {
    margin-bottom: 50px;
    margin-top: 50px;
}
.post__gallery {
    margin-top: 50px;
}
.post__description {
    @extend %text;
    color: $color-black-2;
}
.post__gallery-picture {
    @extend %image-wrap;
    padding-bottom: 56.25%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.post__gallery-image {
    @extend %image-wrap-img;
    @extend %image-cover;
}
.post__gallery-button {
    position: absolute;
    z-index: 3;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    opacity: 0;
    visibility: hidden;
    @extend %a-all-3;
}
.post__gallery-slide:hover .post__gallery-button {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
    visibility: visible;
}
.post__gallery-slide:hover .muar {
    opacity: 1;
    visibility: visible;
}
.post__gallery-nav {
    margin-top: 24px;
    @extend %flex-between;
}
.post__gallery-nav.is-hidden {
    display: none;
}
.post__files {
    margin-top: 50px;
}

.inter-list {
    position: relative;
    overflow: hidden;
    border-top: 1px solid $color-black-5;
}
.inter-list__item {
    border-bottom: 1px solid $color-black-5;
}
.inter-list__link {
    @extend %row;
    @extend %row-margins;
    margin-bottom: 0;
    padding-top: 32px;
    padding-bottom: 32px;
}
.inter-list__left {
    @extend %col-9;
    margin-bottom: 0;
}
.inter-list__right {
    @extend %col-1;
    margin-bottom: 0;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    @extend %column-pr-32;
}
.inter-list__title {
    @extend %h2;
    @extend %text-mb-20;
    transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.inter-list__link:hover .inter-list__title {
    transform: translateX(6px);
    color: $color-accent;
}
.inter-list__summary {
    @extend %text-2;
    color: $color-black-2;
    @extend %text-mb-10;
    transition: all 0.2s 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.inter-list__link:hover .inter-list__summary {
    transform: translateX(6px);
}
.inter-list__badge {
    @extend %text-4;
    text-transform: uppercase;
    letter-spacing: 0.2px;
    padding: 4px 8px;
    display: inline-block;
    background: $color-black-1;
    color: $color-white;
    transition: all 0.2s 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.inter-list__date {
    @extend %text-2;
    color: $color-black-3;
    @extend %a-all-3;
}
.inter-list__link:hover .inter-list__date {
    transform: translateX(6px);
}
