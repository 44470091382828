html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
main, menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    background: none;
    font-size: 100%;
    font-weight: normal;
    font-style: normal;
    font: inherit;
    vertical-align: baseline;
}
article, aside, details, figcaption, figure,
footer, header, hgroup, main, menu, nav, section, summary { display: block; }
body { line-height: 1; }
ol, ul { list-style: none; }
blockquote, q {	quotes: none; }
blockquote:before, blockquote:after,
q:before, q:after { content: ''; content: none; }
table { border-collapse: collapse; border-spacing: 0; }
a { text-decoration: none; }
html { min-height: 100%; font-family: Arial, -apple-system, sans-serif; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; }
audio, canvas, progress, video { display: inline-block; vertical-align: baseline; }
audio:not([controls]) { display: none; height: 0; }
[hidden], template { display: none; }
a { background: transparent; }
a:active, a:hover { outline: 0; }
b, strong { font-weight: bold; }
i, em { font-style: italic; }
small { font-size: 80%; }
sub, sup { font-size: 75%; line-height: 0; position: relative; vertical-align: baseline; }
sup { top: -0.5em; }
sub { bottom: -0.25em; }
svg:not(:root) { overflow: hidden; }
hr { -moz-box-sizing: content-box; box-sizing: content-box; height: 0; }
pre { overflow: auto; }
code, kbd, pre, samp { font-family: monospace, monospace; font-size: 1em; }
button, input, optgroup, select, textarea { color: inherit; font: inherit; }
button { overflow: visible; }
button, select { text-transform: none; }
button, html input[type="button"],
input[type="reset"], input[type="submit"] { -webkit-appearance: button; cursor: pointer; }
button[disabled], html input[disabled] { cursor: default; }
button::-moz-focus-inner, input::-moz-focus-inner { border: 0; padding: 0; }
input { line-height: normal; }
input[type="checkbox"], input[type="radio"] { box-sizing: border-box; }
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button { height: auto; }
input[type="search"] {
    -webkit-appearance: textfield;
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
}
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration { -webkit-appearance: none; }
textarea { overflow: auto; }
optgroup { font-weight: bold; }
