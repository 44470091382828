.pagination-wrap {
    padding-top: 50px;
    margin-top: 50px;
    border-top: 1px solid $color-black-5;
}
.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
}
.pagination__item {
    padding: 0 8px;
    display: block;
}
.pagination__link {
    padding: 0 8px;
    display: block;
    border-bottom: 2px solid transparent;
    @extend %a-all-3;
}
.pagination__link.is-active {
    border-color: $color-black-2;
}
.pagination__link:not(.is-active):hover {
    border-color: $color-accent;
}