.all-projects {
    @extend %container-full;
    @extend %section-padding-bottom-middle;
}
.all-projects__container {
    @extend %container;
}

.project-filter {
    @extend %section-title-mb;
}
.project-filter__items {
    @extend %flex;
}
.project-filter__item {
    @extend %text-2;
    @extend %link;
    color: $color-black-3;
    cursor: pointer;
    border-bottom: 1px solid transparent;
    white-space: nowrap;
}
.project-filter__item.is-active {
    color: $color-black-1;
    border-color: $color-black-2;
    cursor: default;
}
.project-filter__item:not(:last-child) {
    margin-right: 20px;
}

.projects-list {
    position: relative;
    overflow: hidden;
    border-top: 1px solid $color-black-5;
    margin-bottom: 0;
}
.projects-list__item {
    border-bottom: 1px solid $color-black-5;
}
.projects-list__link {
    @extend %row;
    @extend %row-margins;
    margin-bottom: 0;
    padding-top: 32px;
    padding-bottom: 32px;
}
.projects-list__link--msi {
    display: block;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
}
.projects-list__picture {
    @extend %col-3;
    margin-bottom: 0;
    overflow: hidden;
}
.projects-list__info {
    @extend %col-7;
    margin-bottom: 0;
}
.projects-list__title {
    @extend %h2;
    @extend %text-mb-20;
    @extend %a-all-3;
}
.projects-list__link:hover .projects-list__title {
    transform: translateX(6px);
    color: $color-accent;
}
.projects-list__icon {
    @extend %icon;
}
.projects-list__right {
    @extend %col-1;
    margin-bottom: 0;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    @extend %column-pr-32;
}

.projects-list__image-wrap {
    @extend %image-wrap;
    padding-bottom: 56.25%;
    @extend %a-all;
}
.projects-list__link:hover .projects-list__image-wrap {
    transform: scale(1.05);
    transform-origin: center center;
}
.projects-list__image {
    @extend %image-wrap-img;
}
.mix-item.mix-item--hidden {
    opacity: 0 !important;
    max-height: 0;
    border: 0;
    transition-property: transform, opacity, max-height;
}
