*,
*::before,
*::after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    outline: none;
}
html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    height: 100%;
    min-height: 100%;
    width: 100%;
}
body {
    position: relative;
    height: 100%;
    min-height: 100%;
    margin: 0;
    padding: 0;
    background: $color-white;
    @extend %text;
}
body.is-ovh {
    overflow: hidden;
    height: 100%;
}
body.is-search {
    overflow: hidden;
    height: 100%;
}
body.is-scroll {
    overflow: hidden;
}
.page-container {
    min-height: 100%;
    height: 100%;
    position: initial;
}
.content {
    display: flex;
    flex-flow: column;
    min-height: 100%;
    height: auto;
    position: initial;
}
a {
    @extend %link;
}
h1, .h1 {
    @extend %h1;
}
h2, .h2 {
    @extend %h2;
}
h3, .h3 {
    @extend %h3;
}
h4, .h4 {
    @extend %h4;
}
p, .p {
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
    @extend %text-mb-24;
}
p:last-child,
.p:last-child {
    margin-bottom: 0;
}
ul,
ol {
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
    @extend %text-mb-24;
    padding-left: 0;
}
ul ul,
ol ul,
ul ol,
ol ol {
    margin-bottom: 0;
}
img {
    display: block;
    max-width: 100%;
}
strong {
    font-weight: 500;
    color: $color-black-1;
}
.container-full {
    @extend %container-full;
}
.container {
    @extend %container;
}
.main {
    @extend %container-full;
    background: $color-bg-1;
    flex: 1 0 auto;
    overflow: hidden;
}
.main-title {
    @extend %h1;
    color: $color-black-1;
    @extend %main-title-mb;
}
.main-title--no-mb {
    margin-bottom: 0;
}
.main-subtitle {
    @extend %h2;
    color: $color-black-1;
    @extend %main-subtitle-mb;
}
.main-subtitle--with-border-top {
    border-top: 1px solid $color-black-5;
    padding-top: 50px;
}
.main-subtitle--no-mb {
    margin-bottom: 0;
}
.main-subtitle--mb-24 {
    @extend %main-subtitle-mb-24;
}
.section-title {
    @extend %h2;
    color: $color-black-1;
    @extend %section-title-mb;
}
.section-title--no-mb {
    margin-bottom: 0;
}
.label {
    @extend %text-2;
    line-height: 14px;
    color: $color-black-3;
    max-width: 100%;
    margin: 0;
    padding: 0;
    @extend %a-all-3;
}
.label--fly {
    position: absolute;
    top: 5px;
}
.label.is-fly {
    top: -18px;
}
.label--select {
    top: -18px;
}
.input {
    @extend %input;
}
.input--full-width {
    width: 100%;
}
.input:hover {
    border-color: $color-black-2;
    border-width: 1px;
}
.input:focus {
    border-color: $color-accent-light;
    border-width: 1px;
}
.select {
    @extend %select;
}
.select--full-width {
    width: 100%;
}
.textarea {
    @extend %textarea;
}
.textarea--full-width {
    width: 100%;
}
.button {
    @extend %button;
}
.button--transparent {
    background: transparent;
}
.button--inline {
    display: inline-flex;
}
.button-delete {
    @extend %text-2;
    @extend %a-all-3;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.button-delete__icon {
    @extend %icon;
    @extend %a-all-3;
    margin-right: 8px;
}
.button-delete:hover {
    color: $color-accent;
}
.button-delete:hover .button-delete__icon {
    fill: $color-accent;
}
.button__icon {
    @extend %icon;
    fill: $color-white;
}
.button__icon--left {
    margin-right: 8px;
}
.button__icon--right {
    margin-left: 8px;
}
.button__icon--bordered {
    fill: $color-black-1;
}
.button__icon--white {
    fill: $color-white;
}
.button:hover .button__icon--bordered {
    fill: $color-accent-dark;
}
.button--small {
    height: 36px;
}
.button--large {
    height: 48px;
}
.button--full-width {
    width: 100%;
}
.button--bordered {
    background: transparent;
    border: 1px solid $color-black-1;
    color: $color-black-1;
}
.button--bordered:hover {
    background: transparent;
    border-color: $color-accent-dark;
    color: $color-accent-dark;
}
.button--bordered:active {
    background: $color-accent-dark;
    border-color: $color-white;
    color: $color-white;
}
.button--bordered-white {
    border-color: $color-white;
    color: $color-white;
}

.button-rounded {
    @extend %button-rounded;
}
.button-rounded:active:before {
    transform: scale(1.33);
}
.button-rounded__icon {
    @extend %icon;
    fill: $color-white;
    position: relative;
    z-index: 2;
}
.button-rounded--small {
    width: 32px;
    height: 32px;
    flex: 0 0 32px;
}
.button-rounded--small:after {
    width: 32px;
    height: 32px;
}

.button-fly {
    width: 40px;
    height: 40px;
    flex: 0 0 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: transparent;
    border: 1px solid $color-black-5;
    user-select: none;
    outline: none;
    position: relative;
    z-index: 2;
    overflow: hidden;
    cursor: pointer;
    @extend %a-all-3;
}
.button-fly:active {
    border-color: $color-accent;
}
.button-fly:active .button-fly__icon {
    fill: $color-accent;
}
.button-fly__icon {
    @extend %icon;
    position: absolute;
    z-index: 1;
    @extend %a-all-3;
}
.button-fly__icon:first-child {
    transform: translateX(-300%);
    transition: transform .2s .1s ease-out;
}
.button-fly__icon:last-child {
    transform: translateX(0);
    transition: transform .2s ease-out;
}
.button-fly:hover .button-fly__icon:first-child {
    transform: translateX(0);
}
.button-fly:hover .button-fly__icon:last-child {
    transform: translateX(300%);
}
.button-fly-hover:hover .button-fly__icon:first-child {
    transform: translateX(0);
}
.button-fly-hover:hover .button-fly__icon:last-child {
    transform: translateX(300%);
}

.button-control {
    width: 40px;
    height: 40px;
    flex: 0 0 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: transparent;
    border: 1px solid transparent;
    user-select: none;
    outline: none;
    position: relative;
    z-index: 2;
    overflow: hidden;
    cursor: pointer;
}
.button-control:hover {
    @extend %a-all-3;
    border-color: $color-black-5;
}
.button-control:active {
    @extend %a-all-3;
    border-color: $color-accent;
}
.button-control:active .button-control__icon {
    @extend %a-all-3;
    fill: $color-accent;
}
.button-control__icon {
    @extend %icon;
    position: absolute;
    z-index: 1;
    fill: $color-black-2;
    @extend %a-all-3;
}
.button-control__icon:first-child {
    transform: translateX(-300%);
    transition: transform .2s .1s ease-out;
}
.button-control__icon:last-child {
    transform: translateX(0);
    transition: transform .2s ease-out;
}

.button-control:hover .button-control__icon:first-child {
    transform: translateX(0);
}
.button-control:hover .button-control__icon:last-child {
    transform: translateX(300%);
}
.button-control-hover:hover .button-control__icon:first-child {
    transform: translateX(0);
}
.button-control-hover:hover .button-control__icon:last-child {
    transform: translateX(300%);
}

.button-control.button-control--left .button-control__icon:first-child {
    transform: translateX(0);
    transition: transform .2s ease-out;
}
.button-control.button-control--left .button-control__icon:last-child {
    transform: translateX(300%);
    transition: transform .2s .1s ease-out;
}
.button-control.button-control--left:hover .button-control__icon:first-child {
    transform: translateX(-300%);
    transition: transform .2s ease-out;
}
.button-control.button-control--left:hover .button-control__icon:last-child {
    transform: translateX(0);
    transition: transform .2s .1s ease-out;
}

.blockquote {
    position: relative;
    display: block;
    @extend %text-2;
    color: $color-black-2;
}
.blockquote:before {
    content: '';
    position: absolute;
    z-index: 1;
    left: -12px;
    top: 4px;
    bottom: 4px;
    width: 2px;
    background: $color-accent;
}
.section-titlewrap {
    @extend %flex-between;
    @extend %section-title-mb;
    @extend %column-pr-32;
}
.section-titlewrap__title {
    @extend %h2;
    margin-bottom: 0;
}
.goto-link {
    display: flex;
    align-items: center;
}
.goto-link--w-control {
    margin-right: auto;
    margin-left: 20px;
}
.goto-link__text {
    @extend %text-2;
    color: $color-black-2;
    margin-right: 8px;
    @extend %a-color;
}
.goto-link__text--white {
    color: $color-white;
}
.goto-link__icon {
    @extend %icon;
    fill: $color-black-2;
}
.goto-link:hover .goto-link__text {
    color: $color-accent;
}
.goto-link:hover .goto-link__icon {
    fill: $color-accent;
}
.muar {
    background: rgba(0, 0, 0, .65);
    position: absolute;
    z-index: 2;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    visibility: hidden;
    @extend %a-all-3;
}
.muar--fixed {
    position: fixed;
}
.muar--light {
    background: rgba(0, 0, 0, .35);
}
.muar.is-visible {
    opacity: 1;
    visibility: visible;
}
.numbers {
    display: flex;
    align-items: flex-end;
}
.numbers__current {
    @extend %text-2;
    color: $color-black-1;
    line-height: 14px;
    margin-bottom: 4px
}
.numbers__divider {
    @extend %text-2;
    line-height: 20px;
    padding-left: 2px;
    padding-right: 2px;
}
.numbers__total {
    @extend %text-3;
    line-height: 20px;
    color: $color-black-4;
}
.breadcrumbs {
    padding-top: 20px;
    padding-bottom: 60px;
}
.breadcrumbs__list {
    @extend %flex;
    margin: 0;
    overflow-x: auto;
}
.breadcrumbs__item {
    @extend %flex;
}
.breadcrumbs__link {
    @extend %link;
    @extend %text-3;
    display: block;
    color: $color-black-3;
}
.breadcrumbs__name {
    @extend %a-all-3;
    @extend %text-3;
    color: $color-black-3;
    white-space: nowrap;
}
.breadcrumbs__link:hover .breadcrumbs__name {
    color: $color-accent;
}
.breadcrumbs__icon {
    @extend %icon-small;
    margin: 0 8px;
}
.section-header {
    @extend %container-full;
    @extend %section-padding-bottom-middle;
}
.section-header__container {
    @extend %container;
}
.section-header__row {
    @extend %row;
    @extend %row-margins;
    margin-bottom: 0;
    align-items: flex-start;
}
.section-header__left {
    @extend %col-6;
    margin-bottom: 0;
    @extend %column-pr-32;
}
.section-header__title {
    @extend %h1;
    margin-bottom: 0;
}
.section-header__right {
    @extend %col-6;
    margin-bottom: 0;
    @extend %column-pl-32;
}
.section-header__subtitle {
    @extend %h2;
    @extend %text-mb-20;
}
.info {
    @extend %container-full;
}
.info:last-child {
    @extend %section-padding-bottom;
}
.info-line {
    @extend %section-padding-vertical-middle;
    border-top: 1px solid $color-black-5;
}
.info-line__content {
    max-width: 1024px;
}
.info-line__link-msi {
    display: flex;
    align-items: center;
}
.link-list {
    @extend %row;
    @extend %row-margins;
    align-items: flex-start;
}
.link-list__item {
    @extend %col-4;
}
.link-list__link {
    display: flex;
    align-items: flex-start;
}
.link-list__link-go {
    display: flex;
    align-items: center;
    color: $color-black-2;
}
.link-list__picture {
    width: 64px;
    flex: 0 0 64px;
    margin-right: 20px;
    overflow: hidden;
}
.link-list__inner {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 140%;
    background: $color-bg-3;
    position: relative;
    transition: all .2s cubic-bezier(.25,.46,.45,.94);
}
.link-list__link:hover .link-list__inner {
    transform: scale(1.15);
}
.link-list__icon {
    @extend %icon-large;
    fill: $color-black-1;
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.link-list__icon-go {
    @extend %icon;
    @extend %icon-ml;
    color: $color-accent;
}
.link-list__content {
    max-width: 264px;
    padding-top: 8px;
}
.link-list__text {
    @extend %h4;
    color: $color-black-2;
    @extend %text-mb-10;
    transition: all .2s cubic-bezier(.25,.46,.45,.94);
}
.link-list__link:hover .link-list__text {
    transform: translateX(10px);
    color: $color-accent;
}
.link-list__type {
    @extend %text-2;
    color: $color-black-4;
    transition: all .2s .1s cubic-bezier(.25,.46,.45,.94);
}
.link-list__link:hover .link-list__type {
    transform: translateX(10px);
    color: $color-accent-light;
}
.info-row:not(:last-child) {
    @extend %text-mb-40;
}
.info-row__link {
    display: block;
}
.info-row__status {
    @extend %text-4;
    text-transform: uppercase;
    letter-spacing: 0.2px;
    color: $color-white;
    padding: 4px 8px;
    display: inline-block;
    @extend %text-mb-10;
    transition: all .2s cubic-bezier(.25,.46,.45,.94);
    white-space: nowrap;
}
.info-row__status--dark {
    background: $color-black-1;
}
.info-row__status--green {
    background: $color-green;
}
.info-row__link:hover .info-row__status {
    transform: translateX(10px);
}
.info-row__text {
    @extend %text-3;
    color: $color-black-2;
    transition: all .2s cubic-bezier(.25,.46,.45,.94);
}
.info-row__link:hover .info-row__title {
    transform: translateX(10px);
    color: $color-accent;
}
.info-row__link:hover .info-row__text {
    transform: translateX(10px);
    color: $color-accent;
}
.info-row__info {
    @extend %text-2;
    color: $color-black-3;
    @extend %text-mb-10;
    transition: all .2s cubic-bezier(.25,.46,.45,.94);
}
.info-row__link:hover .info-row__info {
    transform: translateX(10px);
}
.info-row__type {
    @extend %text-2;
    color: $color-black-4;
    transition: all .2s .1s cubic-bezier(.25,.46,.45,.94);
    display: inline-block;
}
.info-row__link:hover  .info-row__type {
    transform: translateX(10px);
    color: $color-accent-light;
}
.content-limit {
    max-width: 1024px;
}
body input:-webkit-autofill,
body input:-webkit-autofill:hover,
body input:-webkit-autofill:focus input:-webkit-autofill,
body textarea:-webkit-autofill,
body textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
body select:-webkit-autofill,
body select:-webkit-autofill:hover,
body select:-webkit-autofill:focus {
    border-bottom: 1px solid $color-black-2;
    -webkit-text-fill-color: $color-black-2;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
    background: -webkit-linear-gradient(
                    top,
                    rgba(255, 255, 255, 0) 0%,
                    rgba(0, 174, 255, 0.04) 50%,
                    rgba(255, 255, 255, 0) 51%,
                    rgba(0, 174, 255, 0.03) 100%
    );
    box-shadow: inset 0 0 0 1000px transparent;
}
body input:-internal-autofill-selected {
    background-color: transparent !important;
    background-image: none !important;
    color: $color-black-2 !important;
    box-shadow: inset 0 0 0 1000px transparent;
}
.stripe-loading-indicator .stripe, .stripe-loading-indicator .stripe-loaded {
    background: $color-accent !important;
}
mark {
    color: $color-accent;
    font-weight: 500;
    text-transform: lowercase;
}

.spinner-wrap {
    position: absolute;
    z-index: 2;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: $color-white;
    display: flex;
    align-items: center;
    justify-content: center;
    @extend %a-all-3;
}
.spinner-wrap.is-hidden {
    opacity: 0;
    visibility: hidden;
}
.spinner {
    margin: auto;
    width: 50px;
    height: 40px;
    text-align: center;
    font-size: 10px;
}

.spinner > div {
    background-color: $color-accent;
    height: 100%;
    width: 6px;
    display: inline-block;

    -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
    animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.spinner .rect2 {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
}

.spinner .rect3 {
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
}

.spinner .rect4 {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
}

.spinner .rect5 {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
    0%, 40%, 100% { -webkit-transform: scaleY(0.4) }
    20% { -webkit-transform: scaleY(1.0) }
}

@keyframes sk-stretchdelay {
    0%, 40%, 100% {
        transform: scaleY(0.4);
        -webkit-transform: scaleY(0.4);
    }  20% {
           transform: scaleY(1.0);
           -webkit-transform: scaleY(1.0);
       }
}
.li {
    margin-bottom: 24px;
}
